<template>
  <div class="interaction-container d-f f-d a-c">
    <nav class="d-f j-c a-c">
      <div class="top d-f j-b a-c">
        <div class="left d-f a-c">
          <img :src="tenantLogo" width="32px" v-if="tenantLogo" />
          <img src="@/assets/images/layout_logo.png" width="32px" v-else />
          <span class="title">智慧校园管理平台</span>
          <span class="text">互动课堂</span>
        </div>
        <div class="right">
          <div @click="back" class="back d-f a-c">
            <img src="@/assets/images/terminal_detail_back.png" width="24px" />
            <span>返回</span>
          </div>
        </div>
      </div>
    </nav>
    <div class="middle">
      <div class="text">您的账户已使用时长</div>
      <div class="time-container d-f a-c">
        <div ref="time" class="time"></div>
        <div class="time-text">
          {{ usedDurationHourStr }}小时 / {{ totalDurationHourStr }}小时
        </div>
      </div>
    </div>
    <div class="list">
      <div class="title">互动房间列表（{{ total }}个）</div>
      <div class="space">
        <!--表格-->
        <a-table :columns="columns" rowKey="id" :data-source="data" :pagination="false">
          <span slot="action" slot-scope="text, record">
            <a @click="close(record)" v-hasPermi="['interaction:close']">关闭房间</a>
          </span>
        </a-table>
        <!--分页-->
        <div class="d-f mt20">
          <a-pagination
            v-model="queryParams.page"
            :pageSize="queryParams.limit"
            :default-current="1"
            :total="total"
            :hideOnSinglePage="true"
            showLessItems
            @change="onChange"
            :item-render="itemRender"
            style="margin-left: auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import {
  getInteractionTime,
  getInteractionRoomList,
  closeRoom,
} from "@/api/interaction/classroom.js";
const columns = [
  {
    title: "互动房间",
    dataIndex: "classroomName",
    align: "center",
  },
  {
    title: "房间号",
    dataIndex: "customRoomId",
    align: "center",
  },
  {
    title: "房间人数",
    dataIndex: "userCount",
    align: "center",
  },
  {
    title: "开始时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "InteractionClassroom",
  data() {
    return {
      //时长echarts的DOM
      chartDom: null,
      //总时长
      totalInteractDuration: [],
      //已经使用的时长
      usedInteractDuration: [],
      //总时长小时
      totalDurationHourStr: "",
      //已经使用的时长小时
      usedDurationHourStr: "",
      //互动房间数据表头
      columns,
      //互动房间数据
      data: [],
      //查询表单
      queryParams: {
        page: 1,
        limit: 6,
      },
      //数据总数
      total: null,
    };
  },
  computed: {
    //租户logo
    tenantLogo() {
      return this.$ls.get("userInfo").tenantLogo;
    },
  },
  methods: {
    //返回上一级路由
    back() {
      this.$router.push("/enter");
    },
    //获取互动已用时长和总时长
    getInteractionTime() {
      let schoolNum = this.$ls.get("userInfo").schoolNum;
      getInteractionTime({ schoolNum }).then((res) => {
        this.usedInteractDuration = [res.data.usedInteractDuration];
        this.totalInteractDuration = [res.data.totalInteractDuration];
        this.usedDurationHourStr = (res.data.usedInteractDuration / 3600).toFixed(2);
        this.totalDurationHourStr = (res.data.totalInteractDuration / 3600).toFixed(2);
        this.timeCharts();
      });
    },
    //使用时长的图表
    timeCharts() {
      if (
        //判断是否存在echarts实例化对象,如果存在则销毁
        this.chartsDom != null &&
        this.chartsDom != "" &&
        this.chartsDom != undefined
      ) {
        this.chartsDom.dispose();
      }
      let chartDom = this.$refs.time;
      this.chartsDom = echarts.init(chartDom); //创建echarts实例化对象
      this.chartsDom.clear(); //清空画布数据

      let option = {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          show: false,
          type: "category",
        },
        series: [
          {
            type: "bar",
            data: this.usedInteractDuration,
            barWidth: 8,
            tooltip: { show: false },
            z: 10,
            itemStyle: {
              color: "rgba(255, 255, 255, 1)", // 柱状图颜色
              borderRadius: [4],
              legendHoverLink: false,
              label: {
                show: false, // 显示文本
              },
            },
          },
          {
            type: "bar",
            data: this.totalInteractDuration,
            tooltip: { show: false },
            barWidth: 8,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            itemStyle: {
              borderRadius: [4],
              color: "rgba(255, 255, 255, 0.5)", // 柱子颜色，作为底层背景
              label: {
                show: false,
              },
            },
          },
        ],
      };

      this.chartsDom.setOption(option);
    },
    //获取互动房间列表
    getInteractionRoomList() {
      getInteractionRoomList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //分页器改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getInteractionRoomList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //关闭房间
    close(record) {
      this.$confirm({
        title: "您确认关闭房间吗？",
        onOk: () => {
          closeRoom({ roomId: record.id }).then((res) => {
            if (res.data.Code == 0) {
              this.$message.success("关闭成功");
              this.getInteractionRoomList();
            } else {
              this.$message.error(res.data.Message);
            }
          });
        },
      });
    },
  },
  created() {
    this.getInteractionRoomList();
  },
  mounted() {
    this.getInteractionTime();
    this.timeCharts();
  },
  destroyed() {},
};
</script>

<style scoped lang="less">
.interaction-container {
  width: 100%;
  // min-width: 1440px;
  // overflow-x: auto;
  min-height: 100vh;
  background: rgba(240, 242, 245, 0.39);
  opacity: 1;

  nav {
    width: 100%;
    height: 52px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 21, 41, 0.12);
    opacity: 1;

    .top {
      width: 1184px;

      .left {
        .title {
          margin-left: 8px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }

        .text {
          margin-left: 24px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .right {
        .back {
          margin-left: 42px;
          cursor: pointer;

          span {
            margin-left: 2px;
            font-size: 14px;
            font-family: "PingFang SC";
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
  .middle {
    margin-top: 24px;
    width: 1184px;
    height: 160px;
    background: url("~@/assets/images/interaction_bac.png") no-repeat 0 0 / ~"100%  100%";
    padding: 24px 32px;
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    color: #ffffff;
    .text {
      font-weight: bold;
    }
    .time-container {
      margin-top: 34px;
      .time {
        width: 750px;
        height: 8px;
        margin-right: 16px;
      }
    }
  }
  .list {
    margin-top: 24px;
    width: 1184px;
    min-height: 595px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 45px;
    .title {
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #e9e9e9;
      padding: 24px 32px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    .space {
      width: 100%;
      padding: 24px 32px;
    }
  }
}
</style>
