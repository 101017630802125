<template>
  <div class="layout-content d-f f-d">
    <div class="list-container">
      <div class="query-list">
        <!-- 搜索 -->
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="区域" prop="regionCode" v-if="isRegionRole">
            <a-cascader
              :options="regionOptions"
              change-on-select
              v-model="queryParams.regionCode"
              allowClear
              placeholder="请选择区域"
              style="width: 160px"
              :field-names="{
                label: 'title',
                value: 'id',
                children: 'children',
              }"
              :display-render="displayRender"
              @change="changeRegion"
            />
          </a-form-model-item>
          <a-form-model-item label="学校" prop="schoolNum" v-if="isRegionRole">
            <a-select
              v-model="queryParams.schoolNum"
              allowClear
              placeholder="请选择学校"
              style="width: 150px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="changeSchool"
            >
              <a-select-option
                :value="item.schoolNum"
                v-for="item in schoolOptions"
                :key="item.schoolNum"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="楼栋" prop="buildingId">
            <a-select
              v-model="queryParams.buildingId"
              allowClear
              placeholder="请选择楼栋"
              style="width: 150px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-select-option
                :value="item.id"
                v-for="item in buildingOptions"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="自定义分组" prop="customGroupId">
            <a-select
              v-model="queryParams.customGroupId"
              allowClear
              placeholder="请选择分组"
              style="width: 150px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                v-for="item in groupList"
                :key="item.id"
                :value="item.id"
                >{{ item.groupName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="状态" prop="onlineStatus">
            <a-select
              v-model="queryParams.onlineStatus"
              allowClear
              placeholder="请选择状态"
              style="width: 150px"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option :value="0">在线</a-select-option>
              <a-select-option :value="1">离线</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['device:terminal:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['device:terminal:query']"
              >重置</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div class="command d-f a-c">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            >全选</a-checkbox
          >
          <a-dropdown class="dropdown d-f a-c">
            <a-menu slot="overlay" @click="handleSwitch">
              <a-menu-item
                :key="item.commandIdentification + '-' + item.commandName"
                v-for="item in commandSwitchList"
                >{{ item.commandName }}</a-menu-item
              >
            </a-menu>
            <a-button class="ml12"> <a-icon type="carry-out" />智能开关指令 </a-button>
          </a-dropdown>
          <a-dropdown class="dropdown d-f a-c">
            <a-menu slot="overlay" @click="handleCommand">
              <a-menu-item
                :key="item.commandIdentification + '-' + item.commandName"
                v-for="item in commandList"
                >{{ item.commandName }}</a-menu-item
              >
            </a-menu>
            <a-button class="ml12"> <a-icon type="carry-out" />远程指令 </a-button>
          </a-dropdown>
          <a-dropdown class="dropdown d-f a-c">
            <a-menu slot="overlay" @click="handlePush">
              <a-menu-item :key="item.commandIdentification" v-for="item in pushList">
                {{ item.commandName }}
              </a-menu-item>
            </a-menu>
            <a-button class="ml12"> <a-icon type="desktop" />终端推送 </a-button>
          </a-dropdown>
          <a-button class="ml12" @click="handleTime">
            <a-icon type="clock-circle" />定时任务
          </a-button>
          <img
            src="@/assets/images/terminal_ring.png"
            width="32"
            height="32"
            class="ml24"
            @click="ring"
          />
        </div>
      </div>
      <!-- 设备列表 -->
      <a-checkbox-group
        v-model="checkedList"
        @change="onCheckChange"
        class="terminal-list"
      >
        <div class="box" v-for="item in plainOptions" :key="item.classroomId">
          <img
            class="desktop"
            :src="item.desktop"
            v-if="item.onlineFlag && item.blackboardSerialNum != null"
          />
          <div
            class="dust"
            @click="toTerminalDetail(item)"
            v-if="item.onlineFlag && item.blackboardSerialNum != null"
          ></div>
          <a-tooltip
            placement="bottom"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <template slot="title">
              <span>{{ item.buildingName + "-" + item.classroomName }}</span>
            </template>
            <a-checkbox :value="item.classroomId" class="checkbox">
              {{ item.buildingName + "-" + item.classroomName }}
            </a-checkbox>
          </a-tooltip>
          <a-tooltip
            placement="bottom"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <template slot="title">
              <span>实时功率{{ item.realPower }}W，总耗能{{ item.totalEnergy }}kW·h</span>
            </template>
            <div
              class="power d-f a-c"
              v-if="item.switchOnlineFlag && item.switchSerialNum != null"
            >
              <img src="@/assets/images/terminal_power.png" width="16" class="mr4" />
              {{ item.realPower }}W
            </div>
          </a-tooltip>
          <div class="status-on" v-if="item.onlineFlag || item.switchOnlineFlag">
            在线
          </div>
          <div class="status-off" v-if="!item.onlineFlag && !item.switchOnlineFlag">
            离线
          </div>
          <img
            src="@/assets/images/terminal_analysis.png"
            class="analysis"
            @click="analysis(item)"
          />
          <img
            src="@/assets/images/terminal_statistics.png"
            class="statistics"
            v-if="item.onlineFlag && item.blackboardSerialNum != null"
            @click="statistics(item)"
          />
          <img
            src="@/assets/images/terminal_usb_unknow.png"
            class="usb"
            v-if="
              item.onlineFlag && item.blackboardSerialNum != null && item.usbStatus === 2
            "
            @click="usb(item)"
          />
          <img
            src="@/assets/images/terminal_usb_on.png"
            class="usb"
            v-if="
              item.onlineFlag && item.blackboardSerialNum != null && item.usbStatus === 1
            "
            @click="usb(item)"
          />
          <img
            src="@/assets/images/terminal_usb_off.png"
            class="usb"
            v-if="
              item.onlineFlag && item.blackboardSerialNum != null && item.usbStatus === 0
            "
            @click="usb(item)"
          />
          <img
            src="@/assets/images/terminal_lock_unknow.png"
            class="lock"
            v-if="
              item.onlineFlag &&
              item.blackboardSerialNum != null &&
              item.screenStatus === 2
            "
            @click="lockOrUnlock(item)"
          />
          <img
            src="@/assets/images/terminal_lock_on.png"
            class="lock"
            v-if="
              item.onlineFlag &&
              item.blackboardSerialNum != null &&
              item.screenStatus === 1
            "
            @click="lockOrUnlock(item)"
          />
          <img
            src="@/assets/images/terminal_lock_off.png"
            class="lock"
            v-if="
              item.onlineFlag &&
              item.blackboardSerialNum != null &&
              item.screenStatus === 0
            "
            @click="lockOrUnlock(item)"
          />
          <img
            src="@/assets/images/terminal_video.png"
            class="video"
            v-if="item.onlineFlag && item.blackboardSerialNum != null"
            @click="video(item)"
          />
          <img
            src="@/assets/images/terminal_enlarge.png"
            class="enlarge"
            v-if="item.onlineFlag && item.blackboardSerialNum != null"
            @click="enlarge(item)"
          />
          <div
            class="online d-f a-c j-c"
            v-if="
              (item.blackboardSerialNum == null &&
                item.switchSerialNum != null &&
                item.switchOnlineFlag) ||
              (item.blackboardSerialNum != null &&
                !item.onlineFlag &&
                item.switchSerialNum != null &&
                item.switchOnlineFlag)
            "
          >
            <img
              src="@/assets/images/terminal_switch_online.png"
              width="23"
              height="23"
            />
            <span>智能开关</span>
          </div>
          <div
            class="offline d-f a-c j-c"
            v-if="
              (item.blackboardSerialNum != null &&
                !item.onlineFlag &&
                item.switchSerialNum == null) ||
              (item.blackboardSerialNum == null &&
                !item.switchOnlineFlag &&
                item.switchSerialNum != null) ||
              (item.blackboardSerialNum != null &&
                !item.onlineFlag &&
                !item.switchOnlineFlag &&
                item.switchSerialNum != null)
            "
          >
            <img
              src="@/assets/images/terminal_offline.png"
              width="27"
              v-if="
                item.blackboardSerialNum != null &&
                !item.onlineFlag &&
                item.switchSerialNum == null
              "
            />
            <img
              src="@/assets/images/terminal_switch_offline.png"
              width="23"
              height="23"
              v-if="
                item.blackboardSerialNum == null &&
                !item.switchOnlineFlag &&
                item.switchSerialNum != null
              "
            />
            <span>已离线</span>
          </div>
        </div>
      </a-checkbox-group>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>

      <!-- 当日统计的弹出框 -->
      <a-modal
        v-model="statisticsVisible"
        :footer="null"
        width="690px"
        class="statistics-model"
      >
        <div slot="title">
          <span class="title">
            {{
              statisticsItem.buildingName + "-" + statisticsItem.classroomName
            }}&nbsp;当日数据统计
          </span>
        </div>
        <div class="top">
          <div class="echarts-title d-f j-b a-c">
            <div class="left">当日开机次数分布图</div>
            <div class="right">
              今日开机共<span style="color: #009fe8">{{ statisticsCount }}</span
              >次
            </div>
          </div>
          <div ref="statisticsOnline" class="statistics-online"></div>
        </div>
        <div class="bottom">
          <div class="echarts-title">
            <div class="left">软件内存占用情况</div>
          </div>
          <a-table
            class="mt12"
            size="middle"
            :columns="columns"
            rowKey="id"
            :data-source="statisticsData"
            :pagination="false"
          >
            <span slot="memoryUsage" slot-scope="text">
              <span>{{ text }}MB</span>
            </span>
            <span slot="diskUsage" slot-scope="text">
              <span>{{ text }}MB</span>
            </span>
          </a-table>
        </div>
      </a-modal>

      <!-- 异常分析的弹出框 -->
      <a-modal v-model="analysisVisible" :width="analysisWidth" class="analysis-model">
        <div slot="title">
          <span class="title">
            {{
              analysisItem.buildingName + "-" + analysisItem.classroomName
            }}&nbsp;异常分析
          </span>
        </div>
        <template slot="footer">
          <a-button
            type="primary"
            @click="
              () => {
                this.analysisVisible = false;
              }
            "
          >
            确定
          </a-button>
        </template>
        <div v-if="analysisItem.onlineFlag && analysisItem.blackboardSerialNum != null">
          <ul>
            <li class="d-f">
              <div class="left d-f a-c">
                <img src="@/assets/images/terminal_analysis_temperature.png" width="20" />
                CPU温度
              </div>
              <div class="right d-f a-c">
                <img
                  src="@/assets/images/terminal_analysis_normal.png"
                  width="16"
                  v-if="analysisObj.cpuTemperature < 65"
                />
                <img
                  src="@/assets/images/terminal_analysis_error.png"
                  width="16"
                  v-else
                />
                <span v-if="analysisObj.cpuTemperature < 65">正常</span
                ><span v-else>异常</span> {{ analysisObj.cpuTemperature }}℃
              </div>
            </li>
            <li class="d-f">
              <div class="left d-f a-c">
                <img src="@/assets/images/terminal_analysis_touch.png" width="20" />
                触摸系统
              </div>
              <div class="right d-f a-c">
                <img
                  src="@/assets/images/terminal_analysis_normal.png"
                  width="16"
                  v-if="analysisObj.touchSystem"
                />
                <img
                  src="@/assets/images/terminal_analysis_error.png"
                  width="16"
                  v-else
                />
                <span v-if="analysisObj.touchSystem">正常</span><span v-else>异常</span>
              </div>
            </li>
            <li class="d-f">
              <div class="left d-f a-c">
                <img src="@/assets/images/terminal_analysis_light.png" width="20" />
                光感系统
              </div>
              <div class="right d-f a-c">
                <img
                  src="@/assets/images/terminal_analysis_normal.png"
                  width="16"
                  v-if="analysisObj.lightSystem"
                />
                <img
                  src="@/assets/images/terminal_analysis_error.png"
                  width="16"
                  v-else
                />
                <span v-if="analysisObj.lightSystem">正常</span><span v-else>异常</span>
              </div>
            </li>
            <li class="d-f">
              <div class="left d-f a-c">
                <img src="@/assets/images/terminal_analysis_environment.png" width="20" />
                环境系统
              </div>
              <div class="right d-f a-c">
                <img
                  src="@/assets/images/terminal_analysis_normal.png"
                  width="16"
                  v-if="analysisObj.environmentSystem"
                />
                <img
                  src="@/assets/images/terminal_analysis_error.png"
                  width="16"
                  v-else
                />
                <span v-if="analysisObj.environmentSystem">正常</span
                ><span v-else>异常</span>
              </div>
            </li>
            <li class="d-f">
              <div class="left d-f a-c">
                <img src="@/assets/images/terminal_analysis_computer.png" width="20" />
                内置电脑
              </div>
              <div class="right d-f a-c">
                <img
                  src="@/assets/images/terminal_analysis_normal.png"
                  width="16"
                  v-if="analysisObj.ops"
                />
                <img
                  src="@/assets/images/terminal_analysis_error.png"
                  width="16"
                  v-else
                />
                <span v-if="analysisObj.ops">正常</span><span v-else>异常</span>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="text">
          1.请检测电源是否接通和开启<br />
          2.请检查是否连接互联网 <br />
          3.请检查设备是否注册集控<br />
        </div>
      </a-modal>

      <!-- 大屏巡课 -->
      <div class="enlarge-and-upload" v-if="enlargeVisible">
        <div class="name-and-close d-f a-c j-b">
          <div>{{ enlargeItem.buildingName + "-" + enlargeItem.classroomName }}</div>
          <img src="@/assets/images/terminal_enlarge_close.png" @click="enlargeClose" />
        </div>
        <div class="line"></div>
        <div class="enlarge-space d-f a-c j-c">
          <img
            :src="enlargeItem.desktop"
            v-if="enlargeItem.onlineFlag && enlargeItem.onlineFlag != null"
            @error="defImg"
          />
          <div v-else>
            <img src="@/assets/images/terminal_offline.png" />
            <span class="ml12">已关机</span>
          </div>
          <a-button
            type="primary"
            @click="uploadEnlarge(enlargeItem)"
            v-if="enlargeItem.onlineFlag"
            >下载</a-button
          >
        </div>
      </div>

      <!-- 视频巡课 -->
      <div class="enlarge-and-video" v-if="videoVisible">
        <div class="name-and-close d-f a-c j-b">
          <div>{{ videoItem.buildingName + "-" + videoItem.classroomName }}</div>
          <img src="@/assets/images/terminal_enlarge_close.png" @click="videoClose" />
        </div>
        <div class="line"></div>
        <div class="enlarge-space d-f">
          <ul>
            <li>设备列表</li>
            <li
              v-for="item in videoPlainOptions"
              :key="item.blackboardSerialNum"
              :class="
                item.blackboardSerialNum == videoItem.blackboardSerialNum ? 'active' : ''
              "
              @click="changeVideoItem(item)"
            >
              {{ item.buildingName + "-" + item.classroomName }}
            </li>
          </ul>
          <div class="video-container">
            <div>
              <div class="d-f a-c j-b">
                <a-form-model
                  ref="videoQueryForm"
                  :model="videoQueryParams"
                  layout="inline"
                >
                  <a-form-model-item label="区域" prop="regionCode" v-if="isRegionRole">
                    <a-cascader
                      :options="regionOptions"
                      change-on-select
                      v-model="videoQueryParams.regionCode"
                      allowClear
                      placeholder="请选择区域"
                      style="width: 160px"
                      :field-names="{
                        label: 'title',
                        value: 'id',
                        children: 'children',
                      }"
                      :display-render="displayRender"
                      @change="changeVideoRegion"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      popupClassName="custom-cascader-popup"
                    />
                  </a-form-model-item>
                  <a-form-model-item label="学校" prop="schoolNum" v-if="isRegionRole">
                    <a-select
                      v-model="videoQueryParams.schoolNum"
                      allowClear
                      placeholder="请选择学校"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      @change="changeVideoSchool"
                    >
                      <a-select-option
                        :value="item.schoolNum"
                        v-for="item in videoSchoolOptions"
                        :key="item.schoolNum"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="楼栋" prop="buildingId">
                    <a-select
                      v-model="videoQueryParams.buildingId"
                      allowClear
                      placeholder="请选择楼栋"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      @change="changeVideoBuildingId"
                    >
                      <a-select-option
                        :value="item.id"
                        v-for="item in videoBuildingOptions"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="自定义分组" prop="customGroupId">
                    <a-select
                      v-model="videoQueryParams.customGroupId"
                      allowClear
                      placeholder="请选择分组"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      @change="changeVideoCustomGroupId"
                    >
                      <a-select-option
                        v-for="item in groupList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.groupName }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                </a-form-model>
                <a-form-model
                  ref="channelQueryForm"
                  :model="channelQueryParams"
                  layout="inline"
                >
                  <a-form-model-item label="视频流" prop="channel">
                    <a-select
                      v-model="channelQueryParams.channel"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      @change="changeChannel"
                    >
                      <a-select-option value="SCREEN">黑板</a-select-option>
                      <a-select-option value="CAMERA">摄像头</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div style="margin-top: 8px">
                <a-form-model ref="settingForm" :model="settingForm" layout="inline">
                  <a-form-model-item label="帧率" prop="fps">
                    <a-select
                      v-model="settingForm.fps"
                      placeholder="帧率"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option :value="5">5fps</a-select-option>
                      <a-select-option :value="10">10fps</a-select-option>
                      <a-select-option :value="15">15fps</a-select-option>
                      <a-select-option :value="20">20fps</a-select-option>
                      <a-select-option :value="25">25fps</a-select-option>
                      <a-select-option :value="30">30fps</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="码率" prop="bitrate">
                    <a-select
                      v-model="settingForm.bitrate"
                      placeholder="帧率"
                      style="width: 160px"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option :value="300">bitrate 300</a-select-option>
                      <a-select-option :value="600">bitrate 600</a-select-option>
                      <a-select-option :value="1200">bitrate 1200</a-select-option>
                      <a-select-option :value="1500">bitrate 1500</a-select-option>
                      <a-select-option :value="3000">bitrate 3000</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button type="primary" @click="changefpsAndKbps"> 确定 </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div
                class="d-f a-c j-c"
                style="width: 100%; height: calc(100% - 88px); position: relative"
              >
                <div id="remote-video" style="max-width: 90%; max-height: 90%"></div>
                <div
                  style="width: 90%; height: 90%; position: absolute; background: #696969"
                  class="d-f f-d a-c j-c"
                  v-if="occupied"
                >
                  <img
                    src="@/assets/images/terminal_occupied.png"
                    width="300"
                    height="225"
                  />
                  <h4
                    style="
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: rgba(255, 255, 255, 0.8);
                      margin-top: 24px;
                    "
                  >
                    抱歉该设备正在进行音视频互动，无法巡课
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 消息推送的弹出框 -->
      <a-modal v-model="newsVisible" @ok="newsSendOk" @cancel="newsSendCancel">
        <div slot="title">
          <span>消息推送</span>
          <span style="color: #999999">（已选择{{ checkedList.length }}间教室）</span>
        </div>
        <a-form-model
          ref="ruleNewsForm"
          :model="newsForm"
          :rules="newsRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="展示时长" prop="showDuration">
            <a-select
              v-model="newsForm.showDuration"
              placeholder="请选择展示时长"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in showDurationList"
                :key="item.id"
                >{{ item.valueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="允许关闭" prop="closeFlag">
            <a-select
              v-model="newsForm.closeFlag"
              placeholder="请选择终端是否关闭通知"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in closeFlagList"
                :key="item.id"
                >{{ item.valueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="消息内容" prop="content" class="content">
            <a-input
              v-model="newsForm.content"
              type="textarea"
              placeholder="请输入消息内容"
              @change="getContentLength"
              :rows="6"
            />
            <div class="content-count">{{ contentCount }}/200</div>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 文件下发的弹出框 -->
      <a-modal v-model="fileVisible" @ok="fileSendOk" @cancel="fileSendCancel">
        <div slot="title">
          <span>文件下发</span>
          <span style="color: #999999">（已选择{{ checkedList.length }}间教室）</span>
        </div>
        <a-spin :spinning="confirmLoading">
          <a-form-model
            ref="ruleFileForm"
            :model="fileForm"
            :rules="fileRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="自动打开" prop="autoOpenFlag">
              <a-select
                v-model="fileForm.autoOpenFlag"
                placeholder="请选择是否自动打开"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  :value="item.valueData"
                  v-for="item in autoOpenFlagList"
                  :key="item.id"
                  >{{ item.valueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="上传文件" prop="file">
              <a-upload-dragger
                :file-list="fileList"
                :remove="handleRemove"
                :before-upload="beforeUpload"
                accept=".ppt, .pptx, .xlsx, .xls, .doc, .docx, .zip, .pdf, .png, .jpg"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">点击或将文件拖到这里上传</p>
              </a-upload-dragger>
            </a-form-model-item>
          </a-form-model>
        </a-spin>
      </a-modal>

      <!-- 定时任务的弹出框 -->
      <a-modal
        v-model="timeVisible"
        @ok="timeSendOk"
        @cancel="timeSendCancel"
        width="800px"
      >
        <div slot="title">
          <span>定时任务</span>
          <span style="color: #999999">（已选择{{ checkedList.length }}间教室）</span>
        </div>
        <a-spin :spinning="timeConfirmLoading">
          <a-form-model
            ref="ruleTimeForm"
            :model="timeForm"
            :rules="timeRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="操作类型" prop="actionType">
              <a-radio-group v-model="timeForm.actionType" @change="changeActionType">
                <a-radio
                  :value="item.commandIdentification"
                  v-for="item in timeList"
                  :key="item.commandIdentification"
                  >{{ item.commandName }}</a-radio
                >
              </a-radio-group>
            </a-form-model-item>
            <div
              v-if="
                timeForm.actionType == '19' ||
                timeForm.actionType == '20' ||
                timeForm.actionType == '35'
              "
            >
              <a-form-model-item label="循环模式" prop="scheduleCycle">
                <a-radio-group v-model="timeForm.scheduleCycle" @change="changeCycle">
                  <a-radio value="每天">每天</a-radio>
                  <a-radio value="工作日">工作日</a-radio>
                  <a-radio value="周末">周末</a-radio>
                  <a-radio value="自定义">自定义</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="执行周期"
                prop="week"
                v-if="timeForm.scheduleCycle == '自定义'"
              >
                <a-select
                  v-model="timeForm.week"
                  placeholder="请选择执行周期"
                  style="width: 150px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option value="周日">周日</a-select-option>
                  <a-select-option value="周一">周一</a-select-option>
                  <a-select-option value="周二">周二</a-select-option>
                  <a-select-option value="周三">周三</a-select-option>
                  <a-select-option value="周四">周四</a-select-option>
                  <a-select-option value="周五">周五</a-select-option>
                  <a-select-option value="周六">周六</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="执行周期" v-else>
                <div
                  style="
                    width: 100%;
                    height: 38px;
                    border: 1px solid #cfcfcf;
                    background-color: #e4e3e3;
                    color: #535353;
                    text-align: center;
                    line-height: 38px;
                    cursor: not-allowed;
                  "
                >
                  {{ weekText }}
                </div>
              </a-form-model-item>
              <a-form-model-item label="执行时间" prop="executeTime">
                <a-time-picker v-model="timeForm.executeTime" format="HH:mm" />
              </a-form-model-item>
            </div>
            <div v-else-if="timeForm.actionType == '21'">
              <a-form-model-item label="展示时长" prop="showDuration">
                <a-select
                  v-model="timeForm.showDuration"
                  placeholder="请选择展示时长"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in showDurationList"
                    :key="item.id"
                    >{{ item.valueName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="允许关闭" prop="closeFlag">
                <a-select
                  v-model="timeForm.closeFlag"
                  placeholder="请选择终端是否关闭通知"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="
                    () => {
                      this.$forceUpdate();
                    }
                  "
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in closeFlagList"
                    :key="item.id"
                    >{{ item.valueName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="消息内容" prop="content" class="content">
                <a-input
                  v-model="timeForm.content"
                  type="textarea"
                  placeholder="请输入消息内容"
                  @change="getContentLengthToo"
                  :rows="6"
                />
                <div class="content-count">{{ contentCountToo }}/200</div>
              </a-form-model-item>
              <a-form-model-item label="执行日期" prop="date">
                <a-date-picker
                  v-model="timeForm.date"
                  placeholder="请选择执行日期"
                  valueFormat="YYYY-MM-DD"
                  :disabledDate="disabledDate"
                />
              </a-form-model-item>
              <a-form-model-item label="执行时间" prop="executeTime">
                <a-time-picker v-model="timeForm.executeTime" format="HH:mm" />
              </a-form-model-item>
            </div>
            <div v-else>
              <a-form-model-item label="自动打开" prop="autoOpenFlag">
                <a-select
                  v-model="timeForm.autoOpenFlag"
                  placeholder="请选择是否自动打开"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.valueData"
                    v-for="item in autoOpenFlagList"
                    :key="item.id"
                    >{{ item.valueName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="上传文件" prop="file">
                <a-upload-dragger
                  :file-list="timeFileList"
                  :remove="timeHandleRemove"
                  :before-upload="timeBeforeUpload"
                  accept=".ppt, .pptx, .xlsx, .xls, .doc, .docx, .zip, .pdf, .png, .jpg"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">点击或将文件拖到这里上传</p>
                </a-upload-dragger>
              </a-form-model-item>
              <a-form-model-item label="执行日期" prop="date">
                <a-date-picker
                  v-model="timeForm.date"
                  placeholder="请选择执行日期"
                  valueFormat="YYYY-MM-DD"
                  :disabledDate="disabledDate"
                />
              </a-form-model-item>
              <a-form-model-item label="执行时间" prop="executeTime">
                <a-time-picker v-model="timeForm.executeTime" format="HH:mm" />
              </a-form-model-item>
            </div>
          </a-form-model>
        </a-spin>
      </a-modal>

      <!-- 打铃的弹出框 -->
      <a-modal
        v-model="ringVisible"
        @ok="ringSendOk"
        @cancel="ringSendCancel"
        width="750px"
        class="ring-model"
      >
        <div slot="title">
          <span class="title"> 智能打铃 </span>
        </div>
        <a-form-model
          ref="ruleRingForm"
          :model="ringForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="打铃时长" prop="ringDuration">
            <a-select
              v-model="ringForm.ringDuration"
              placeholder="请选择打铃时长"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              style="width: 275px"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-select-option
                :value="item.valueData"
                v-for="item in ringDurationList"
                :key="item.id"
                >{{ item.valueName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="打铃音量" prop="ringVolume">
            <a-radio-group
              v-model="ringForm.ringVolume"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-radio
                :value="item.valueData"
                v-for="item in ringVolumeList"
                :key="item.id"
                >{{ item.valueName }}</a-radio
              >
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="打铃类型" prop="ringType">
            <a-radio-group
              v-model="ringForm.ringType"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-radio
                :value="item.valueData"
                v-for="item in ringTypeList"
                :key="item.id"
                >{{ item.valueName }}</a-radio
              >
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="打铃方式" prop="ringWay">
            <a-radio-group
              v-model="ringForm.ringWay"
              @change="
                () => {
                  this.$forceUpdate();
                }
              "
            >
              <a-radio value="0">立即打铃</a-radio>
              <a-radio value="1">定时打铃</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div v-if="ringForm.ringWay == '1'">
            <a-form-model-item label="打铃次数" prop="ringNumber">
              <a-select
                v-model="ringForm.ringNumber"
                placeholder="请选择打铃次数"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                style="width: 275px"
                @change="
                  () => {
                    this.$forceUpdate();
                  }
                "
              >
                <a-select-option
                  :value="item.valueData"
                  v-for="item in ringNumberList"
                  :key="item.id"
                  >{{ item.valueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="执行日期" prop="ringDay">
              <a-checkbox-group v-model="ringForm.ringDay">
                <a-checkbox value="1" name="1"> 周一 </a-checkbox>
                <a-checkbox value="2" name="2"> 周二 </a-checkbox>
                <a-checkbox value="3" name="3"> 周三 </a-checkbox>
                <a-checkbox value="4" name="4"> 周四 </a-checkbox>
                <a-checkbox value="5" name="5"> 周五 </a-checkbox>
                <a-checkbox value="6" name="6"> 周六 </a-checkbox>
                <a-checkbox value="7" name="7"> 周日 </a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="时间点" prop="ringTime">
              <a-time-picker :open.sync="open" @change="changeRingTime" format="HH:mm">
                <a-button
                  slot="addon"
                  size="small"
                  type="primary"
                  @click="closeTimePicker"
                >
                  确定
                </a-button>
              </a-time-picker>
              <div class="plus d-f a-c j-c" @click="addRingTime">
                <img src="@/assets/images/terminal_ring_add.png" width="16" height="16" />
              </div>
            </a-form-model-item>
            <ul class="ring-time d-f f-w">
              <li v-for="(item, index) in ringTimeList" :key="index">
                {{ item }}
                <img
                  src="@/assets/images/terminal_ring_del.png"
                  width="16"
                  height="16"
                  @click="delRingTime(index)"
                />
              </li>
            </ul>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getTerminalClassroomList,
  scheduleJobAdd,
  getStreamToken,
} from "@/api/device/terminal.js";
import { getCommandList } from "@/api/device/command.js";
import { getGroupList } from "@/api/device/group.js";
import { getBindRegionList } from "@/api/setting/region.js";
import { getSchoolListByRegion } from "@/api/basic-info/school.js";
import { getBuildingListBySchool } from "@/api/basic-info/building.js";
import { getConfigByConfigKey } from "@/api/setting/config.js";
import { getStsInfo } from "@/api/cloud-disk/document.js";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { v4 as uuidv4 } from "uuid";
import Client from "@/utils/oss.js";
import moment from "moment";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
let echarts = require("echarts");
const columns = [
  {
    title: "软件",
    dataIndex: "softwareName",
    align: "left",
  },
  {
    title: "内存",
    dataIndex: "memoryUsage",
    align: "center",
  },
  {
    title: "硬盘",
    dataIndex: "diskUsage",
    align: "center",
  },
  {
    title: "系统",
    dataIndex: "os",
    align: "center",
  },
];
export default {
  name: "Terminal",
  data() {
    return {
      //是否是区域管理员
      isRegionRole: true,
      //区域列表
      regionOptions: [],
      //学校列表
      schoolOptions: [],
      //楼栋列表
      buildingOptions: [],
      //自定义分组列表
      groupList: [],
      //智能开关指令
      commandSwitchList: [],
      //指令列表
      commandList: [],
      //消息推送指令列表
      pushList: [],
      //设备数据
      data: [],
      //设备数量
      total: null,
      //查询表单
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 12,
      },
      //label和wrapper的占位
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      //负责样式控制
      indeterminate: false,
      //是否全选
      checkAll: false,
      //选中的复选框数组
      checkedList: [],
      //复选框数组
      plainOptions: [],
      //websocket发送
      websocket: null,
      //发送心跳的定时器
      timer: null,
      //发送查询设备列表信息的定时器
      timer1: null,
      //websocket连接需要的标识
      uuid: "",
      //大屏展示显示隐藏
      enlargeVisible: false,
      //大屏展示信息
      enlargeItem: {},
      //大屏展示图片错误
      defaultImg: require("@/assets/images/terminal_bac.png"),
      //视频巡课显示隐藏
      videoVisible: false,
      //视频巡课信息
      videoItem: {},
      //视频巡课表单
      videoQueryParams: {
        //不分页
        isPage: false,
        //在线状态
        onlineStatus: 0,
      },
      //视频巡课列表
      videoPlainOptions: [],
      //学校列表
      videoSchoolOptions: [],
      //楼栋列表
      videoBuildingOptions: [],
      //自定义分组列表
      videoGroupList: [],
      //视频流需要的参数
      streamQuery: {},
      //流实例
      zg: null,
      //流Id数组
      streamIdList: [],
      //流地址
      streamID: null,
      //视频流表单
      channelQueryParams: {
        channel: "CAMERA",
      },
      //帧率和码率表单
      settingForm: {
        fps: 15,
        bitrate: 3000,
      },
      //互动占用图片的显示隐藏
      occupied: false,
      //当日统计弹出框显示隐藏
      statisticsVisible: false,
      //当日统计信息
      statisticsItem: {},
      //当日统计开机次数
      statisticsCount: 0,
      //当日统计时间列表
      statisticsTimeList: [
        "0:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      //当日统计开机次数列表
      statisticsOnlineList: [],
      //当天日期
      dateTime: moment().endOf("day").format("YYYY-MM-DD"),
      //软件占有情况表头
      columns,
      //软件占有情况表格数据
      statisticsData: [],
      //异常分析弹出框显示隐藏
      analysisVisible: false,
      //异常分析信息
      analysisItem: {},
      //异常分析弹框宽度
      analysisWidth: "520px",
      //异常分析参数
      analysisObj: {},
      //消息推送弹出框显示隐藏
      newsVisible: false,
      //展示时长列表
      showDurationList: [],
      //终端关闭通知
      closeFlagList: [],
      //消息通知内容汉字数
      contentCount: 0,
      //消息推送表单
      newsForm: {},
      //消息推送表单规则
      newsRules: {
        content: [
          {
            required: true,
            message: "请输入消息内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            message: "请输入1-200之内的汉字",
            max: 200,
            trigger: ["change", "blur"],
          },
        ],
        showDuration: [
          {
            required: true,
            message: "请选择展示时长",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        closeFlag: [
          {
            required: true,
            message: "请选择是否允许终端关闭",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //文件下发显示隐藏
      fileVisible: false,
      //文件大小最大限制
      fileMaxSize: 0,
      //文件列表
      fileList: [],
      //文件下发表单
      fileForm: {},
      //文件下发表单规则
      fileRules: {
        autoOpenFlag: [
          {
            required: true,
            message: "请选择是否允许自动打开",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //文件上传的loading效果
      confirmLoading: false,
      //sts密钥
      dataObj: null,
      //自动打开列表
      autoOpenFlagList: [],
      //定时任务弹出框显示隐藏
      timeVisible: false,
      //定时任务列表
      timeList: [],
      //定时任务表单
      timeForm: {},
      //定时任务表单规则
      timeRules: {
        actionType: [
          {
            required: true,
            message: "请选择操作类型",
            trigger: ["change", "blur"],
          },
        ],
        scheduleCycle: [
          {
            required: true,
            message: "请选择循环模式",
            trigger: ["change", "blur"],
          },
        ],
        week: [
          {
            required: true,
            message: "请选择执行周期",
            trigger: ["change", "blur"],
          },
        ],
        showDuration: [
          {
            required: true,
            message: "请选择展示时长",
            trigger: ["change", "blur"],
          },
        ],
        closeFlag: [
          {
            required: true,
            message: "请选择终端是否关闭通知",
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入消息内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            message: "请输入1-200之内的汉字",
            max: 200,
            trigger: ["change", "blur"],
          },
        ],
        autoOpenFlag: [
          {
            required: true,
            message: "请选择是否自动打开文件",
            trigger: ["change", "blur"],
          },
        ],
        date: [
          {
            required: true,
            message: "请选择执行日期",
            trigger: ["change", "blur"],
          },
        ],
        executeTime: [
          {
            required: true,
            message: "请选择执行时间",
            trigger: ["change", "blur"],
          },
        ],
      },
      //执行周期的文字
      weekText: "周一至周日",
      //定时任务消息通知内容汉字数
      contentCountToo: 0,
      //定时任务文件列表
      timeFileList: [],
      //定时任务文件上传的loading效果
      timeConfirmLoading: false,
      //打铃弹出框显示隐藏
      ringVisible: false,
      //打铃表单
      ringForm: {},
      //打铃时长列表
      ringDurationList: [],
      //打铃音量列表
      ringVolumeList: [],
      //打铃类型列表
      ringTypeList: [],
      //打铃次数列表
      ringNumberList: [],
      //打铃时间列表
      ringTimeList: [],
      open: false,
    };
  },
  watch: {
    enlargeItem(newVal, oldVal) {
      if (newVal != null && oldVal != null) {
        if (newVal.desktop != oldVal.desktop) {
          this.enlargeItem.desktop = newVal.desktop;
        }
      }
    },
  },
  methods: {
    //查询智能开关指令列表
    getCommandSwitchList() {
      getCommandList({ isPage: false, commandType: "5" }).then((res) => {
        this.commandSwitchList = res.data;
      });
    },
    //查询终端指令列表
    getCommandList() {
      getCommandList({ isPage: false, commandType: "2" }).then((res) => {
        this.commandList = res.data;
      });
    },
    //查询推送指令列表
    getPushList() {
      getCommandList({ isPage: false, commandType: "3" }).then((res) => {
        this.pushList = res.data;
      });
    },
    //查询自定义分组列表
    getGroupList() {
      getGroupList({ isPage: false }).then((res) => {
        this.groupList = res.data;
      });
    },
    //获取区域管理员绑定的区域列表
    getBindRegionList() {
      getBindRegionList().then((res) => {
        this.regionOptions = res.data;
      });
    },
    //选择后展示的渲染函数
    displayRender({ labels, selectedOptions }) {
      // 初始化时selectedOptions为空数组，当选择某一项关闭弹窗的时候会触发这个方法，lables，selectedOptions返回为多级数组，如果是想要数据里面的id或者其他参数，可以使用selectedOptions
      if (selectedOptions.length === 0) {
        // 初始化和点击清除按钮触发
        this.queryParams.regionCodes = null;
      } else {
        let regionCode = selectedOptions[selectedOptions.length - 1].id;
        let arr = [];
        arr.push(regionCode);
        this.queryParams.regionCodes = arr;
      }
      return labels[labels.length - 1];
    },
    //选择区域改变
    changeRegion(value) {
      this.queryParams.schoolNum = undefined;
      this.queryParams.buildingId = undefined;
      this.schoolOptions = [];
      this.buildingOptions = [];
      if (value.length > 0) {
        this.getSchoolListByRegion(value[value.length - 1]);
      }
    },
    //获取区域下的学校列表
    getSchoolListByRegion(regionCode) {
      getSchoolListByRegion({ regionCodes: regionCode }).then((res) => {
        this.schoolOptions = res.data;
      });
    },
    //选择学校改变
    changeSchool(value) {
      this.queryParams.buildingId = undefined;
      this.buildingOptions = [];
      if (value) {
        this.getBuildingListByRegion(value);
      }
    },
    //获取学校下的楼栋列表
    getBuildingListByRegion(schoolNum) {
      getBuildingListBySchool({ schoolNum }).then((res) => {
        this.buildingOptions = res.data;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getTerminalClassroomList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams = {
        //当前页
        page: 1,
        //一页几个
        limit: 12,
      };
      this.getTerminalClassroomList();
    },
    //查询教室终端设备
    getTerminalClassroomList() {
      this.plainOptions = [];
      this.checkAll = false;
      this.checkedList = [];
      this.indeterminate = false;
      clearInterval(this.timer1);
      this.timer1 = null;
      let params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.regionCode;
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === undefined) {
          delete params[key];
        }
      });
      if (params.regionCodes) {
        params.regionCodes = params.regionCodes + "";
      }

      getTerminalClassroomList(params).then((res) => {
        this.data = res.data;
        this.total = res.count;
        if (this.websocket != null) {
          this.websocketQueryList();
        } else {
          this.initWebSocket();
        }
      });
    },
    //单个复选框改变
    onCheckChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    //全选改变
    onCheckAllChange(e) {
      let arr = this.plainOptions.map((r) => r.classroomId);
      this.checkedList = e.target.checked ? arr : [];
      this.indeterminate = false;
      this.checkAll = e.target.checked;
    },
    //选择智能开关指令改变
    handleSwitch(e) {
      console.log(e);
      let i = e.key.indexOf("-");
      let commandIdentification = Number(e.key.substr(0, i));
      let commandName = e.key.substr(i + 1);

      if (this.checkedList.length === 0) {
        this.$message.error("请选择你想操作的教室");
      } else {
        let arr = [];
        this.checkedList.forEach((r) => {
          this.plainOptions.forEach((rr) => {
            if (r === rr.classroomId) {
              arr.push(rr.switchSerialNum);
            }
          });
        });
        let flag = arr.every((r) => r != null);
        if (flag) {
          this.$confirm({
            title: `您确认批量${commandName}吗？`,
            onOk: () => {
              let obj = websocketSend(this.uuid, commandIdentification, null, arr);
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(obj));
              }
            },
          });
        } else {
          this.$message.error("请只勾选有智能开关的教室");
        }
      }
    },
    //远程指令
    handleCommand(e) {
      console.log(e);
      let i = e.key.indexOf("-");
      let commandIdentification = Number(e.key.substr(0, i));
      let commandName = e.key.substr(i + 1);

      if (this.checkedList.length === 0) {
        this.$message.error("请选择你想操作的教室");
      } else {
        let arr = [];
        let arr1 = [];
        this.checkedList.forEach((r) => {
          this.plainOptions.forEach((rr) => {
            if (r === rr.classroomId) {
              arr.push(rr.onlineFlag);
              arr1.push(rr.blackboardSerialNum);
            }
          });
        });
        let flag = arr.every((r) => r === true);
        if (flag) {
          this.$confirm({
            title: `您确认批量${commandName}吗？`,
            onOk: () => {
              let obj = websocketSend(this.uuid, commandIdentification, null, arr1);
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(obj));
              }
            },
          });
        } else {
          this.$message.error("请只勾选有在线黑板的教室");
        }
      }
    },
    //终端推送
    async handlePush(e) {
      if (this.checkedList.length === 0) {
        this.$message.error("请选择你想操作的教室");
      } else {
        let arr = [];
        this.checkedList.forEach((r) => {
          this.plainOptions.forEach((rr) => {
            if (r === rr.classroomId) {
              arr.push(rr.onlineFlag);
            }
          });
        });
        let flag = arr.every((r) => r === true);
        if (flag) {
          //消息推送
          if (Number(e.key) === 8) {
            await this.getShowDurationList();
            await this.getCloseFlagList();
            this.newsVisible = true;
          }
          //文件下发
          else {
            await this.getAutoOpenFlagList();
            let res = await getConfigByConfigKey({ configKey: "fileMaxSize" });
            this.fileMaxSize = res.data;
            this.fileVisible = true;
          }
        } else {
          this.$message.error("请只勾选有在线黑板的教室");
        }
      }
    },
    //去设备详情页
    toTerminalDetail(item) {
      if (item.onlineFlag && item.blackboardSerialNum != null) {
        this.$router.push({
          path: "/device/terminal-detail",
          query: {
            serialNum: item.blackboardSerialNum,
            deviceName: item.buildingName + "-" + item.classroomName,
          },
        });
      }
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getTerminalClassroomList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      // let token = this.$ls.get("access_token");
      // this.websocket = new WebSocket(wsurl, [token]); //实例对象
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询教室列表信息
    websocketQueryList() {
      console.log("请求查询设备列表信息");
      let queryTerminalClassroom = websocketSend(
        this.uuid,
        actionType.queryTerminalClassroom,
        this.data
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryTerminalClassroom));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(queryTerminalClassroom));
          }
        }, 10 * 1000);
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryList();
      }
      //查询教室列表信息成功
      else if (data.code === 8050) {
        //视频巡课
        if (this.videoVisible) {
          this.videoPlainOptions = data.data;
        } else {
          this.plainOptions = data.data;

          //大屏展示图片变化功能
          if (this.enlargeItem != null) {
            let plainOptions = JSON.parse(JSON.stringify(this.plainOptions));
            let enlargeItem = JSON.parse(JSON.stringify(this.enlargeItem));
            let newEnlargeItem = {};
            plainOptions.forEach((r) => {
              if (r.blackboardSerialNum === enlargeItem.blackboardSerialNum) {
                newEnlargeItem = r;
              }
            });
            if (newEnlargeItem.desktop != enlargeItem.desktop) {
              this.enlargeItem = newEnlargeItem;
            }
          }
        }
      }
      //消息推送后返回
      else if (data.code === 8012) {
        this.$message.success(data.msg);
        this.newsVisible = false;
        this.newsSendCancel();
      }
      //文件下发后返回
      else if (data.code === 8014) {
        this.$message.success("上传成功");
        this.confirmLoading = false;
        this.fileVisible = false;
        this.fileSendCancel();
      }
      //文件下发后返回
      else if (data.code === -8014) {
        this.$message.error(data.msg);
        this.confirmLoading = false;
      }
      //客户端收到推流信息，web开始拉流
      else if (data.code === 8027) {
        this.$message.success("正在获取视频资源");
        // 项目唯一标识 AppID，Number 类型，请从 ZEGO 控制台获取
        let appID = this.streamQuery.appId;
        // 接入服务器地址 Server，String 类型，请从 ZEGO 控制台获取
        let server = this.streamQuery.server;

        let userID = this.$ls.get("userInfo").id + "-" + this.uuid;
        let roomID = this.videoItem.blackboardSerialNum;
        let token = this.streamQuery.token;

        if (this.zg == null) {
          // 初始化实例
          this.zg = new ZegoExpressEngine(appID, server);
          //登录房间
          this.zg
            .loginRoom(
              roomID,
              token,
              {
                userID,
                userName:
                  this.channelQueryParams.channel +
                  "-" +
                  this.settingForm.fps +
                  "-" +
                  this.settingForm.bitrate,
              },
              { userUpdate: true }
            )
            .then((result) => {
              if (result == true) {
                console.log("login success");
              }
            });

          //zego token过期处理
          this.zg.on("tokenWillExpire", (roomID) => {
            // 重新请求开发者服务端获取 Token
            getStreamToken({
              uid: this.$ls.get("userInfo").id + "-" + this.uuid,
            }).then((res) => {
              this.streamQuery = res.data;
              this.zg.renewToken(this.streamQuery.token);
            });
          });

          // 流状态更新回调
          this.zg.on(
            "roomStreamUpdate",
            async (roomID, updateType, streamList, extendedData) => {
              //当updateType为ADD时，代表有音视频流新增，此时可以调用startPlayingStream接口拉取播放该音视频流
              if (updateType == "ADD") {
                //流新增
                console.log("流增加");
                //this.streamIdList加入新的流
                streamList.forEach((r) => {
                  this.streamIdList.push(r.streamID);
                });
                //这里为了使示例代码更加简洁，我们只拉取新增的音视频流列表中第的第一条流，在实际的业务中，建议开发者循环遍历 streamList ，拉取每一条音视频流
                this.startPlayingStream();
              }
              // 流删除，停止拉流
              else if (updateType == "DELETE") {
                // 流删除，通过流删除列表 streamList 中每个流的 streamID 进行停止拉流。
                // const streamID = streamList[0].streamID;
                streamList.forEach((element) => {
                  if (
                    element.streamID ===
                    roomID + "-" + this.channelQueryParams.channel
                  ) {
                    this.zg.stopPlayingStream(element.streamID);
                    this.streamIdList.splice(
                      this.streamIdList.indexOf(element.streamID),
                      1
                    );
                  }
                });
              }
            }
          );

          //收到广播消息的通知
          this.zg.on("IMRecvBroadcastMessage", (roomID, chatData) => {
            console.log("广播消息IMRecvBroadcastMessage", roomID, chatData);
            let message = JSON.parse(chatData[0].message);
            //切换流
            if (message.msgType === 0) {
              message.data.forEach(async (r) => {
                //可以拉流
                if (
                  this.streamIdList.includes(
                    roomID + "-" + this.channelQueryParams.channel
                  ) &&
                  r.channel === this.channelQueryParams.channel &&
                  r.status != 0
                ) {
                  console.log("可以拉流");
                  this.startPlayingStream();
                } else if (
                  r.channel === this.channelQueryParams.channel &&
                  r.status == 0
                ) {
                  this.$message.error(r.msg);
                }
              });
            }
            //设备在被互动占用
            else if (message.msgType === 1) {
              this.$message.error("设备被互动占用");
              if (this.zg != null && this.streamID != null) {
                //停止拉流
                this.zg.stopPlayingStream(this.streamID);
                //退出房间
                this.zg.logoutRoom(roomID);
                this.streamID = null;
              }
              this.zg = null;
              this.occupied = true;
            }
          });
        }
      }
      //单个设备各个软件内存占用情况返回
      else if (data.code === 8038) {
        this.statisticsData = data.data;
      }
      //单个设备开机时间列表
      else if (data.code === 7001) {
        if (data.data == null) {
          this.statisticsCount = 0;
          this.statisticsOnlineList = [];
        } else {
          this.statisticsCount = data.data.count;
          let arr = [];
          for (let i = 0; i < 24; i++) {
            arr[i] = 0;
          }
          for (let i = 0; i < 24; i++) {
            data.data.list.forEach((r) => {
              if (i === Number(r.hour)) {
                arr[i] = r.count;
              }
            });
          }
          this.statisticsOnlineList = arr;
        }

        setTimeout(() => {
          this.myCharts();
        });
      }
      //异常分析返回
      else if (data.code === 7037) {
        this.analysisObj = data.data;
      }
      //立即打铃后返回
      else if (data.code === 8011) {
        this.$message.success(data.msg);
        this.ringVisible = false;
      } else if (data.code > 0) {
        this.$message.success(data.msg);
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      if (this.videoVisible && this.zg != null && this.streamID != null) {
        let roomID = this.videoItem.blackboardSerialNum;
        //停止拉流
        this.zg.stopPlayingStream(this.streamID);
        //退出房间
        this.zg.logoutRoom(roomID);
        this.streamID = null;
        this.zg = null;
      }
      this.videoVisible = false;
    },
    //当日统计
    statistics(item) {
      this.statisticsItem = item;
      this.statisticsVisible = true;
      let arr = [];
      arr.push(item.blackboardSerialNum);
      console.log("发送单个设备各个软件内存占用情况");
      let queryMemoryPercentage = websocketSend(
        this.uuid,
        actionType.queryMemoryPercentage,
        null,
        arr
      );
      console.log("发送单个设备开机时间列表");
      let queryTurnOnCount = websocketSend(
        this.uuid,
        actionType.queryTurnOnCount,
        null,
        arr
      );
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(queryMemoryPercentage));
        this.websocket.send(JSON.stringify(queryTurnOnCount));
      }
    },
    //当日开机次数分布图
    myCharts() {
      let myChart = echarts.getInstanceByDom(this.$refs.statisticsOnline);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.statisticsOnline);
      }
      // 指定图表的配置项和数据
      let option = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          data: this.statisticsTimeList,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          minInterval: 1,
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            zlevel: 2,
            z: 2,
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#009FE8",
              width: 2,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              color: "rgba(0,159,232,0.35)",
            },
            data: this.statisticsOnlineList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#009FE8",
              type: "dashed",
            },
          },
          borderColor: "#ffffff",
          backgroundColor: "#ffffff",
          extraCssText: "box-shadow: 0px 2px 8px 1px rgba(0,47,69,0.15)",
          padding: 10,
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div style="color:rgba(0,0,0,0.45)">${this.dateTime} ${obj.time}</div>
             <div style="margin-top:5px";color: rgba(0,0,0,0.65)>开机次数：${obj.value}次</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //异常分析
    analysis(item) {
      this.analysisItem = item;
      //黑板在线
      if (item.onlineFlag && item.blackboardSerialNum != null) {
        this.analysisWidth = "690px";
        let arr = [];
        arr.push(item.blackboardSerialNum);
        console.log("发送异常分析设备自检");
        let deviceSelfCheck = websocketSend(
          this.uuid,
          actionType.deviceSelfCheck,
          null,
          arr
        );
        if (this.websocket == null) {
          this.$message.error("连接已断开，请刷新页面");
          this.websocketclose();
        } else {
          this.websocket.send(JSON.stringify(deviceSelfCheck));
        }
      } else {
        this.analysisWidth = "520px";
      }
      this.analysisVisible = true;
    },
    //锁定/解锁
    lockOrUnlock(item) {
      this.$confirm({
        title: "您确认锁定/解锁吗？",
        onOk: () => {
          let arr = [];
          arr.push(item.blackboardSerialNum);
          console.log("发送锁定/解锁指令");
          let lockOrUnlock = websocketSend(this.uuid, actionType.lockOrUnlock, null, arr);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(lockOrUnlock));
          }
        },
      });
    },
    //usb禁用/启用
    usb(item) {
      this.$confirm({
        title: "您确认usb禁用/启用吗？",
        onOk: () => {
          let arr = [];
          arr.push(item.blackboardSerialNum);
          console.log("发送usb禁用/启用指令");
          let usb = websocketSend(this.uuid, actionType.usb, null, arr);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(usb));
          }
        },
      });
    },
    //大屏展示
    enlarge(item) {
      this.enlargeVisible = true;
      this.enlargeItem = item;
    },
    //关闭大屏巡课
    enlargeClose() {
      this.enlargeVisible = false;
      this.enlargeItem = null;
    },
    //大屏展示图片出错
    defImg(event) {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    //下载大屏巡课的图片
    uploadEnlarge(item) {
      let url = "";
      if (item.onlineFlag && item.desktop != null) {
        url = item.desktop;
        const elink = document.createElement("a");
        elink.style.display = "none";
        elink.href = url;
        document.body.appendChild(elink);
        elink.click();
        // URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }
    },
    //打开视频巡课
    video(item) {
      clearInterval(this.timer1);
      this.timer1 = null;
      this.zg = null;
      this.occupied = false;
      this.videoVisible = true;
      this.videoItem = item;
      if (!this.isRegionRole) {
        this.getVideoBuildingListByRegion(null);
      }
      this.getVideoPlainOptions();
      this.getStreamToken();
    },
    //关闭视频巡课
    videoClose() {
      this.videoVisible = false;
      if (this.zg != null && this.streamID != null) {
        let roomID = this.videoItem.blackboardSerialNum;
        //停止拉流
        this.zg.stopPlayingStream(this.streamID);
        //退出房间
        this.zg.logoutRoom(roomID);
        this.streamID = null;
        this.zg = null;
      }
      this.videoItem = null;
      this.plainOptions = [];
      clearInterval(this.timer1);
      this.timer1 = null;
      let params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.regionCode;
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === undefined) {
          delete params[key];
        }
      });
      if (params.regionCodes) {
        params.regionCodes = params.regionCodes + "";
      }
      getTerminalClassroomList(params).then((res) => {
        this.data = res.data;
        this.total = res.count;
        if (this.websocket != null) {
          this.websocketQueryList();
        } else {
          this.initWebSocket();
        }
      });
    },
    //获取视频流token
    getStreamToken() {
      getStreamToken({
        uid: this.$ls.get("userInfo").id + "-" + this.uuid,
      }).then((res) => {
        this.streamQuery = res.data;
        //外网访问推流
        console.log("发送推流指令");
        let startStreaming = websocketSend(
          this.uuid,
          actionType.startStreaming,
          {
            channel: this.channelQueryParams.channel,
            fps: this.settingForm.fps,
            bitrate: this.settingForm.bitrate,
            networkType: "WAN",
          },
          [this.videoItem.blackboardSerialNum]
        );
        if (this.websocket == null) {
          this.$message.error("连接已断开，请刷新页面");
          this.websocketclose();
        } else {
          this.websocket.send(JSON.stringify(startStreaming));
        }
      });
    },
    //查询视频巡课列表
    getVideoPlainOptions() {
      let params = JSON.parse(JSON.stringify(this.videoQueryParams));
      delete params.regionCode;
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === undefined) {
          delete params[key];
        }
      });
      if (params.regionCodes) {
        params.regionCodes = params.regionCodes + "";
      }

      getTerminalClassroomList(params).then((res) => {
        console.log("请求查询视频巡课设备列表信息");
        let queryTerminalClassroom = websocketSend(
          this.uuid,
          actionType.queryTerminalClassroom,
          res.data
        );
        if (this.websocket == null) {
          this.$message.error("连接已断开，请刷新页面");
          this.websocketclose();
        } else {
          this.websocket.send(JSON.stringify(queryTerminalClassroom));
        }
      });
    },
    //选择区域改变
    changeVideoRegion(value) {
      this.videoQueryParams.schoolNum = undefined;
      this.videoQueryParams.buildingId = undefined;
      this.videoSchoolOptions = [];
      this.videoBuildingOptions = [];
      if (value.length > 0) {
        this.getVideoSchoolListByRegion(value[value.length - 1]);
      }
      this.getVideoPlainOptions();
    },
    //获取区域下的学校列表
    getVideoSchoolListByRegion(regionCode) {
      getSchoolListByRegion({ regionCodes: regionCode }).then((res) => {
        this.videoSchoolOptions = res.data;
      });
    },
    //选择学校改变
    changeVideoSchool(value) {
      this.videoQueryParams.buildingId = undefined;
      this.videoBuildingOptions = [];
      if (value) {
        this.getVideoBuildingListByRegion(value);
      }
      this.getVideoPlainOptions();
    },
    //获取学校下的楼栋列表
    getVideoBuildingListByRegion(schoolNum) {
      getBuildingListBySchool({ schoolNum }).then((res) => {
        this.videoBuildingOptions = res.data;
      });
    },
    //选择楼栋改变
    changeVideoBuildingId() {
      this.$forceUpdate();
      this.getVideoPlainOptions();
    },
    //自定义分组改变
    changeVideoCustomGroupId() {
      this.getVideoPlainOptions();
    },
    //视频巡课时设备列表改变
    changeVideoItem(item) {
      let roomID = this.videoItem.blackboardSerialNum;
      if (this.zg != null && this.streamID != null) {
        //停止拉流
        this.zg.stopPlayingStream(this.streamID);
        //退出房间
        this.zg.logoutRoom(roomID);
        this.streamID = null;
      }
      this.zg = null;
      this.occupied = false;
      this.videoItem = item;
      this.getStreamToken();
    },
    //帧率和码率改变
    async changefpsAndKbps() {
      //有实例有摄像头
      if (this.zg) {
        this.zg.stopPlayingStream(this.streamID);
        let roomID = this.videoItem.blackboardSerialNum;
        let inputMessage =
          this.channelQueryParams.channel +
          "-" +
          this.settingForm.fps +
          "-" +
          this.settingForm.bitrate +
          "-1";
        try {
          await this.zg.sendBroadcastMessage(roomID, inputMessage);
        } catch (error) {
          console.log(">>> sendMsg, error: ", error);
        }
      }
      //没有实例或者实例被销毁
      else {
        //没有被互动占用 建立实例
        if (!this.occupied) {
          this.getStreamToken();
        }
      }
    },
    //视频流通道改变
    async changeChannel() {
      //有实例有摄像头
      if (this.zg) {
        this.zg.stopPlayingStream(this.streamID);
        let roomID = this.videoItem.blackboardSerialNum;
        let inputMessage =
          this.channelQueryParams.channel +
          "-" +
          this.settingForm.fps +
          "-" +
          this.settingForm.bitrate +
          "-0";
        try {
          await this.zg.sendBroadcastMessage(roomID, inputMessage);
        } catch (error) {
          console.log(">>> sendMsg, error: ", error);
        }
      }
      //没有实例或者实例被销毁
      else {
        //没有被互动占用 建立实例
        if (!this.occupied) {
          this.getStreamToken();
        }
      }
    },
    //开始拉流
    async startPlayingStream() {
      let roomID = this.videoItem.blackboardSerialNum;
      for (let i = 0; i < this.streamIdList.length; i++) {
        if (this.streamIdList[i] === roomID + "-" + this.channelQueryParams.channel) {
          this.streamID = this.streamIdList[i];
          // streamList中有对应流的 streamID
          const remoteStream = await this.zg.startPlayingStream(this.streamID);
          // 创建媒体流播放组件对象，用于播放远端媒体流 。
          const remoteView = this.zg.createRemoteStreamView(remoteStream);
          document.getElementById("remote-video").innerHTML = "";
          // 将播放组件挂载到页面，"remote-video" 为组件容器 DOM 元素的 id 。
          remoteView.play("remote-video");
          break;
        }
      }
    },
    //获取消息展示时长列表
    getShowDurationList() {
      this.getDictDataList("device_terminal_show_duration").then((res) => {
        this.showDurationList = res.data;
      });
    },
    //获取终端关闭通知列表
    getCloseFlagList() {
      this.getDictDataList("device_terminal_close_flag").then((res) => {
        this.closeFlagList = res.data;
      });
    },
    //获取文件自动打开列表
    getAutoOpenFlagList() {
      this.getDictDataList("device_terminal_auto_open_flag").then((res) => {
        this.autoOpenFlagList = res.data;
      });
    },
    //消息通知内容改变
    getContentLength() {
      this.contentCount = this.newsForm.content.length;
    },
    //消息推送确定
    newsSendOk() {
      this.$refs.ruleNewsForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: "您确认消息推送吗？",
            onOk: () => {
              let arr = [];
              this.plainOptions.forEach((r) => {
                this.checkedList.forEach((rr) => {
                  if (r.classroomId == rr) {
                    arr.push(r.blackboardSerialNum);
                  }
                });
              });
              this.newsForm.showChannel = "1";
              let newsSend = websocketSend(
                this.uuid,
                actionType.newsSend,
                this.newsForm,
                arr
              );
              if (this.websocket == null) {
                this.$message.error("连接已断开，请刷新页面");
                this.websocketclose();
              } else {
                this.websocket.send(JSON.stringify(newsSend));
              }
            },
          });
        }
      });
    },
    //消息推送取消
    newsSendCancel() {
      this.$refs.ruleNewsForm.resetFields();
      this.contentCount = 0;
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(file) {
      if (file.size < this.fileMaxSize) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
      } else {
        this.$message.error("文件大小超过限制");
      }
      return false;
    },
    //文件下发确定
    fileSendOk() {
      this.$refs.ruleFileForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: "您确认文件下发吗？",
            onOk: async () => {
              if (this.fileList.length == 0) {
                this.$message.error("请上传文件");
              } else {
                this.confirmLoading = true;
                //获取Token
                let res = await getStsInfo();
                const {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  securityToken,
                } = res.data;
                this.dataObj = {
                  region,
                  bucket,
                  accessKeyId,
                  accessKeySecret,
                  stsToken: securityToken,
                };
                let time = new Date().getTime();
                let fileName = time + "_" + this.fileList[0].name;
                let tenantId = this.$ls.get("userInfo").tenantId;
                let schoolNum = this.$ls.get("userInfo").schoolNum;
                let userId = this.$ls.get("userInfo").id;
                let filePath = `shareFile/${tenantId}/${schoolNum}/${userId}/${fileName}`;
                Client(this.dataObj)
                  .multipartUpload(filePath, this.fileList[0], {
                    partSize: 2 * 1024 * 1024,
                    progress: (p) => {},
                  })
                  .then((result) => {
                    if (result.res.statusCode === 200) {
                      let arr = [];
                      this.plainOptions.forEach((r) => {
                        this.checkedList.forEach((rr) => {
                          if (r.classroomId == rr) {
                            arr.push(r.blackboardSerialNum);
                          }
                        });
                      });
                      let fileSend = websocketSend(
                        this.uuid,
                        actionType.fileSend,
                        {
                          fileName,
                          filePath,
                          autoOpenFlag: this.fileForm.autoOpenFlag,
                        },
                        arr
                      );
                      console.log(fileSend);
                      if (this.websocket == null) {
                        this.$message.error("连接已断开，请刷新页面");
                        this.websocketclose();
                      } else {
                        this.websocket.send(JSON.stringify(fileSend));
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$message.error(error);
                  });
              }
            },
          });
        }
      });
    },
    //文件下发取消
    fileSendCancel() {
      this.$refs.ruleFileForm.resetFields();
      this.fileList = [];
    },
    //查询推送指令列表
    getTimeList() {
      getCommandList({ isPage: false, commandType: "4" }).then((res) => {
        this.timeList = res.data;
      });
    },
    //定时任务
    async handleTime() {
      if (this.checkedList.length > 0) {
        await this.getTimeList();
        await this.getShowDurationList();
        await this.getCloseFlagList();
        await this.getAutoOpenFlagList();
        let res = await getConfigByConfigKey({ configKey: "fileMaxSize" });
        this.fileMaxSize = res.data;
        this.timeForm = {};
        this.timeForm.actionType = actionType.scheduleTurnOff.toString();
        this.timeForm.week = "周日";
        this.timeForm.closeFlag = this.closeFlagList[0].valueData;
        this.timeConfirmLoading = false;
        this.timeFileList = [];
        this.timeVisible = true;
      } else {
        this.$message.error("请选择你想操作的教室");
      }
    },
    //不可选的日期
    disabledDate(current) {
      return current < moment().subtract(1, "day");
    },
    moment,
    //定时任务操作类型改变
    changeActionType(e) {
      this.$refs.ruleTimeForm.resetFields();
      this.timeForm = {};
      this.timeForm.actionType = e.target.value;
      this.timeForm.week = "周日";
      this.timeForm.closeFlag = this.closeFlagList[0].valueData;
      this.$forceUpdate();
    },
    //定时任务改变循环模式
    changeCycle(e) {
      switch (e.target.value) {
        case "每天":
          this.weekText = "周一至周日";
          break;
        case "工作日":
          this.weekText = "周一至周五";
          break;
        case "周末":
          this.weekText = "周六至周日";
          break;
        default:
          break;
      }
    },
    //定时任务消息通知内容改变
    getContentLengthToo() {
      this.$forceUpdate();
      this.contentCountToo = this.timeForm.content.length;
    },
    //定时任务移除选择的文件
    timeHandleRemove(file) {
      const index = this.timeFileList.indexOf(file);
      const newFileList = this.timeFileList.slice();
      newFileList.splice(index, 1);
      this.timeFileList = newFileList;
    },
    //定时任务上传后提交前的操作
    timeBeforeUpload(file) {
      if (file.size < this.fileMaxSize) {
        this.timeFileList = [];
        this.timeFileList = [...this.timeFileList, file];
      } else {
        this.$message.error("文件大小超过限制");
      }
      return false;
    },
    //定时任务确定
    timeSendOk() {
      this.$refs.ruleTimeForm.validate(async (valid) => {
        if (valid) {
          let params = {};
          params.deviceList = this.checkedList;
          params.jobStatus = 1;
          params.jobType = "4";
          params.concurrent = 0;
          params.misfirePolicy = 0;
          params.executeTime = moment(this.timeForm.executeTime).format("HH:mm") + ":00";
          params.actionType = this.timeForm.actionType;
          let hh = params.executeTime.substr(0, 2);
          let mm = params.executeTime.substr(3, 2);
          let ss = params.executeTime.substr(6, 2);
          if (hh[0] === "0") {
            hh = hh[1];
          }
          if (mm[0] === "0") {
            mm = mm[1];
          }
          if (ss[0] === "0") {
            ss = ss[1];
          }
          console.log(hh, mm, ss);
          //定时关机或者重启
          if (
            this.timeForm.actionType == actionType.scheduleTurnOff ||
            this.timeForm.actionType == actionType.scheduleRestart ||
            this.timeForm.actionType == actionType.scheduleTurnOn
          ) {
            if (this.timeForm.scheduleCycle == "自定义") {
              params.scheduleCycle = this.timeForm.week;
              switch (this.timeForm.week) {
                case "周日":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "SUN";
                  break;
                case "周一":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "MON";
                  break;
                case "周二":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "TUE";
                  break;
                case "周三":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "WED";
                  break;
                case "周四":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "THU";
                  break;
                case "周五":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "FRI";
                  break;
                case "周六":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "SAT";
                  break;
                default:
                  break;
              }
            } else {
              params.scheduleCycle = this.timeForm.scheduleCycle;
              switch (this.timeForm.scheduleCycle) {
                case "每天":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "*" + " " + "*" + " " + "?";
                  break;
                case "工作日":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "MON-FRI";
                  break;
                case "周末":
                  params.cronExpression =
                    ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "SAT,SUN";
                  break;
                default:
                  break;
              }
            }
            console.log(params);
            scheduleJobAdd(params).then((res) => {
              this.$message.success("设置成功");
              this.timeVisible = false;
            });
          }
          //定时消息推送
          else if (this.timeForm.actionType == actionType.scheduleNewsSend) {
            params.scheduleCycle = this.timeForm.date;
            params.showDuration = this.timeForm.showDuration;
            params.closeFlag = this.timeForm.closeFlag;
            params.content = this.timeForm.content;
            let year = this.timeForm.date.substr(0, 4);
            let month = this.timeForm.date.substr(5, 2);
            let dd = this.timeForm.date.substr(8, 2);
            if (month[0] === "0") {
              month = month[1];
            }
            if (dd[0] === "0") {
              dd = dd[1];
            }
            params.cronExpression =
              ss + " " + mm + " " + hh + " " + dd + " " + month + " " + "?" + " " + year;

            scheduleJobAdd(params).then((res) => {
              this.$message.success("设置成功");
              this.timeVisible = false;
            });
          }
          //文件下发
          else {
            if (this.timeFileList.length == 0) {
              this.$message.error("请上传文件");
            } else {
              params.scheduleCycle = this.timeForm.date;
              params.autoOpenFlag = this.timeForm.autoOpenFlag;
              let year = this.timeForm.date.substr(0, 4);
              let month = this.timeForm.date.substr(5, 2);
              let dd = this.timeForm.date.substr(8, 2);
              if (month[0] === "0") {
                month = month[1];
              }
              if (dd[0] === "0") {
                dd = dd[1];
              }
              params.cronExpression =
                ss +
                " " +
                mm +
                " " +
                hh +
                " " +
                dd +
                " " +
                month +
                " " +
                "?" +
                " " +
                year;
              this.timeConfirmLoading = true;
              //获取Token
              let res = await getStsInfo();
              const {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                securityToken,
              } = res.data;
              this.dataObj = {
                region,
                bucket,
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken,
              };
              let time = new Date().getTime();
              let fileName = time + "_" + this.timeFileList[0].name;
              let tenantId = this.$ls.get("userInfo").tenantId;
              let schoolNum = this.$ls.get("userInfo").schoolNum;
              let userId = this.$ls.get("userInfo").id;
              let filePath = `shareFile/${tenantId}/${schoolNum}/${userId}/${fileName}`;
              Client(this.dataObj)
                .multipartUpload(filePath, this.timeFileList[0], {
                  partSize: 2 * 1024 * 1024,
                  progress: (p) => {},
                })
                .then((result) => {
                  if (result.res.statusCode === 200) {
                    params.filePath = filePath;
                    params.fileName = this.timeFileList[0].name;
                    scheduleJobAdd(params).then((res) => {
                      this.timeConfirmLoading = false;
                      this.$message.success("设置成功");
                      this.timeVisible = false;
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$message.error(error);
                });
            }
          }
        }
      });
    },
    //定时任务取消
    timeSendCancel() {
      this.$refs.ruleTimeForm.resetFields();
      this.contentCountToo = 0;
      this.timeFileList = [];
      this.confirmLoading = false;
    },
    //获取打铃时长列表
    getRingDurationList() {
      this.getDictDataList("device_terminal_ring_duration").then((res) => {
        this.ringDurationList = res.data;
        if (this.ringDurationList.some((r) => r.isDefault == 0)) {
          this.ringForm.ringDuration = this.ringDurationList.filter(
            (r) => r.isDefault == 0
          )[0].valueData;
        } else {
          this.ringForm.ringDuration = this.ringDurationList[0].valueData;
        }
      });
    },
    //获取打铃音量列表
    getRingVolumeList() {
      this.getDictDataList("device_terminal_ring_volume").then((res) => {
        this.ringVolumeList = res.data;
        if (this.ringVolumeList.some((r) => r.isDefault == 0)) {
          this.ringForm.ringVolume = this.ringVolumeList.filter(
            (r) => r.isDefault == 0
          )[0].valueData;
        } else {
          this.ringForm.ringVolume = this.ringVolumeList[0].valueData;
        }
      });
    },
    //获取打铃类型列表
    getRingTypeList() {
      this.getDictDataList("device_terminal_ring_type").then((res) => {
        this.ringTypeList = res.data;
        if (this.ringTypeList.some((r) => r.isDefault == 0)) {
          this.ringForm.ringType = this.ringTypeList.filter(
            (r) => r.isDefault == 0
          )[0].valueData;
        } else {
          this.ringForm.ringType = this.ringTypeList[0].valueData;
        }
      });
    },
    //获取打铃次数列表
    getRingNumberList() {
      this.getDictDataList("device_terminal_ring_number").then((res) => {
        this.ringNumberList = res.data;
        if (this.ringNumberList.some((r) => r.isDefault == 0)) {
          this.ringForm.ringNumber = this.ringNumberList.filter(
            (r) => r.isDefault == 0
          )[0].valueData;
        } else {
          this.ringForm.ringNumber = this.ringNumberList[0].valueData;
        }
      });
    },
    //打铃
    async ring() {
      if (this.checkedList.length === 0) {
        this.$message.error("请选择你想操作的教室");
      } else {
        this.ringForm = {};
        this.ringForm.ringWay = "0";
        this.ringTimeList = [];
        //打开打铃弹框
        await this.getRingDurationList();
        await this.getRingVolumeList();
        await this.getRingTypeList();
        await this.getRingNumberList();
        this.ringVisible = true;
      }
    },
    //打铃确定
    ringSendOk() {
      //立即打铃
      if (this.ringForm.ringWay == "0") {
        let params = {};
        params.ringWay = "0";
        params.ringDuration = this.ringForm.ringDuration;
        params.ringVolume = this.ringForm.ringVolume;
        params.ringType = this.ringForm.ringType;

        let arr = [];
        let arr1 = [];
        this.checkedList.forEach((r) => {
          this.plainOptions.forEach((rr) => {
            if (r === rr.classroomId) {
              arr1.push(rr.onlineFlag);
              arr.push(rr.blackboardSerialNum);
            }
          });
        });
        arr = arr.filter((item) => item !== null);
        let flag = arr1.every((r) => r === true);
        if (flag) {
          let ring = websocketSend(this.uuid, actionType.ring, params, arr);
          console.log(ring);
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(ring));
          }
        } else {
          this.$message.error("请只勾选有在线黑板的教室");
        }
      }
      //定时打铃
      else {
        if (this.ringForm.ringDay == undefined || this.ringForm.ringDay.length == 0) {
          this.$message.error("请选择执行日期");
        } else if (this.ringTimeList.length == 0) {
          this.$message.error("请选择执行时间");
        } else {
          let params = {};
          params.ringWay = "1";
          params.ringDuration = this.ringForm.ringDuration;
          params.ringVolume = this.ringForm.ringVolume;
          params.ringType = this.ringForm.ringType;
          params.ringNumber = this.ringForm.ringNumber;
          params.deviceList = this.checkedList;
          params.jobStatus = 1;
          params.jobType = "4";
          params.concurrent = 0;
          params.misfirePolicy = 0;
          params.executeTimeList = this.ringTimeList;
          params.actionType = actionType.scheduleRing;

          let sortArr = JSON.parse(JSON.stringify(this.ringForm.ringDay));
          let sortArr1 = sortArr.sort((a, b) => {
            return Number(a) - Number(b);
          });
          let weekList = [];
          let weekcronList = [];
          sortArr1.forEach((r) => {
            let index = Number(r) - 1;
            let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"][index];
            let weekcron = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"][index];
            weekList.push(week);
            weekcronList.push(weekcron);
          });
          params.scheduleCycle = weekList.join(",");

          let cronExpressionList = [];
          function getTime(time) {
            let hh = time.substr(0, 2);
            let mm = time.substr(3, 2);
            let ss = time.substr(6, 2);
            if (hh[0] === "0") {
              hh = hh[1];
            }
            if (mm[0] === "0") {
              mm = mm[1];
            }
            if (ss[0] === "0") {
              ss = ss[1];
            }
            return ss + " " + mm + " " + hh;
          }
          this.ringTimeList.forEach((r) => {
            let timeStr = getTime(r);
            let cronExpression =
              timeStr + " " + "?" + " " + "*" + " " + weekcronList.join(",");
            cronExpressionList.push(cronExpression);
          });
          params.cronExpressionList = cronExpressionList;
          console.log(params);
          scheduleJobAdd(params).then((res) => {
            this.$message.success("设置成功");
            this.ringVisible = false;
          });
        }
      }
    },
    //打铃取消
    ringSendCancel() {},
    //打铃时间改变
    changeRingTime(time, timeString) {
      console.log(time, timeString);
      this.ringForm.ringTime = timeString + ":00";
    },
    //增加打铃时间
    addRingTime() {
      if (this.ringForm.ringTime != null && this.ringForm.ringTime != "") {
        this.ringTimeList.push(this.ringForm.ringTime);
      }
    },
    //删除打铃时间
    delRingTime(index) {
      this.ringTimeList.splice(index, 1);
    },
    closeTimePicker() {
      this.open = false;
    },
  },
  created() {
    this.getCommandSwitchList();
    this.getCommandList();
    this.getPushList();
    this.getGroupList();
    this.isRegionRole = this.$ls
      .get("userInfo")
      .roles.some((r) => r.roleAlias === "region-admin");
    if (this.isRegionRole) {
      this.getBindRegionList();
    } else {
      this.getBuildingListByRegion(null);
    }
    getTerminalClassroomList(this.queryParams).then((res) => {
      this.data = res.data;
      this.total = res.count;
      this.initWebSocket();
    });
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-dropdown-menu-item {
  text-align: center;
  padding: 5px 0;
}

.layout-content {
  width: 100%;
  height: 100%;

  .list-container {
    width: 100%;
    height: 100%;
    padding: 24px;

    .query-list {
      width: 100%;
      background: #ffffff;
      height: 152px;
      padding: 0 24px;
      position: relative;

      .ant-row {
        margin-top: 24px;
      }

      .command {
        position: absolute;
        right: 24px;
        bottom: 24px;
      }
    }

    ::v-deep .ant-checkbox-group {
      display: flex;
    }

    .terminal-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(auto, 1fr);
      row-gap: 24px;
      column-gap: 18px;
      margin-top: 24px;

      .box {
        padding-bottom: 55%;
        border-radius: 8px;
        position: relative;

        .dust {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #0a1421;
          border-radius: 8px;
          z-index: 88;
          opacity: 0.4;
        }

        .desktop {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }

        .checkbox {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #fffbfb;
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 99;
          width: 58%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .power {
          position: absolute;
          top: 10px;
          right: 42px;
          font-size: 14px;
          font-family: DIN-Regular, DIN;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          z-index: 99;
        }
        .status-on {
          position: absolute;
          top: 10px;
          right: 0;
          width: 35px;
          height: 20px;
          background: #4bc908;
          border-radius: 8px 0px 0px 8px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 20px;
          z-index: 99;
        }

        .status-off {
          position: absolute;
          top: 10px;
          right: 0;
          width: 35px;
          height: 20px;
          background: #b70505;
          border-radius: 8px 0px 0px 8px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 20px;
          z-index: 99;
        }

        .analysis {
          position: absolute;
          left: 6px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .statistics {
          position: absolute;
          left: 38px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .usb {
          position: absolute;
          right: 102px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .lock {
          position: absolute;
          right: 70px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .video {
          position: absolute;
          right: 38px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .enlarge {
          position: absolute;
          right: 6px;
          bottom: 6px;
          width: 20px;
          cursor: pointer;
          z-index: 99;
        }

        .offline {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 8px;
          background: #0a1421;
          z-index: 88;

          span {
            margin-left: 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .online {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 8px;
          background: #114f8d;
          z-index: 88;

          span {
            margin-left: 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.enlarge-and-upload {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  background-color: #000e1e;

  .name-and-close {
    width: 100%;
    height: 62px;
    padding: 0 32px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.13);
    opacity: 0.61;
  }

  .enlarge-space {
    width: 100%;
    height: calc(~"100vh - 63px");
    position: relative;

    > img {
      width: 70%;
    }

    span {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      color: rgba(255, 255, 255, 0.6);
    }

    button {
      position: absolute;
      bottom: 32px;
      right: 24px;
    }
  }
}

.enlarge-and-video {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  background-color: #000000;

  .name-and-close {
    width: 100%;
    height: 62px;
    padding: 0 32px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
  }

  .enlarge-space {
    width: 100%;
    height: calc(~"100vh - 63px");

    ::v-deep .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.85);
    }

    ::v-deep .ant-cascader-picker {
      background-color: transparent;
    }

    ::v-deep .ant-cascader-input.ant-input {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    ::v-deep .ant-cascader-picker-arrow {
      color: rgba(255, 255, 255, 0.25);
    }

    ::v-deep .ant-cascader-picker-clear {
      color: rgba(256, 256, 256, 0.85);
      background: transparent;
    }

    ::v-deep .ant-cascader-picker-label {
      color: rgba(255, 255, 255, 0.65);
    }

    ::v-deep .ant-select-selection {
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: transparent;
    }

    ::v-deep .ant-select-arrow {
      color: rgba(255, 255, 255, 0.25);
    }

    ::v-deep .ant-select-selection-selected-value {
      color: rgba(255, 255, 255, 0.65);
    }

    ::v-deep .ant-select-selection__clear {
      color: rgba(256, 256, 256, 0.85);
      background: transparent;
    }

    ::v-deep .ant-select-dropdown {
      background: #222222;
      color: rgba(255, 255, 255, 0.65);
    }

    ::v-deep .ant-select-dropdown-menu-item {
      color: rgba(256, 256, 256, 0.65);
    }

    ::v-deep .ant-select-dropdown-menu-item-selected {
      background-color: transparent;
      color: @primary-color;
    }

    ::v-deep
      .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: transparent;
      color: @primary-color;
    }

    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }

    video {
      object-fit: cover;
      border-radius: 8px;
    }

    ul {
      height: 100%;
      width: 180px;
      overflow-y: auto;
      padding: 24px 24px 0px 24px;
      box-sizing: border-box;
      overflow-y: scroll;

      li:first-child {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
      }

      li {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }

      .active {
        color: @primary-color;
      }

      li:not(:first-child):hover {
        color: @primary-color;
      }
    }

    ul::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 1px;
    }

    ul::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      background: rgba(255, 255, 255, 0.4);
    }

    ul::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      background: rgba(255, 255, 255, 0.15);
    }

    .video-container {
      width: calc(~"100% - 180px");
      height: 100%;
      padding: 24px;
      box-sizing: border-box;
      color: #ffffff;
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.content {
  position: relative;

  .content-count {
    position: absolute;
    right: 1px;
    bottom: -38px;
    color: rgba(0, 0, 0, 0.25);
    opacity: 0.85;
  }
}

::v-deep .ant-calendar-picker-input {
  cursor: default;
}

::v-deep .ant-time-picker-input {
  cursor: default;
}
::v-deep .ant-checkbox + span {
  padding-right: 0px;
  padding-left: 4px;
}
::v-deep .ant-tooltip-placement-bottom {
  padding-top: 0;
}
::v-deep .ant-tooltip-arrow {
  display: none;
}
::v-deep .ant-tooltip-inner {
  background: #d9dee5;
  box-shadow: 0px 9px 28px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px 2px 2px 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #222222;
}

.statistics-model {
  ::v-deep .ant-modal-header {
    background: #f0f2f5;
  }
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .echarts-title {
    .left {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    .right {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .statistics-online {
    width: 100%;
    height: 185px;
    margin-top: 32px;
  }
  .bottom {
    margin-top: 24px;
  }
}
.analysis-model {
  ::v-deep .ant-modal-header {
    background: #f0f2f5;
  }
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 8px;
  }
  ul {
    margin-top: 16px;
    margin-left: 12px;
    margin-bottom: 15px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    li {
      .left {
        margin-right: 60px;
        color: rgba(0, 0, 0, 0.85);
        img {
          margin-right: 12px;
        }
      }
      .right {
        color: rgba(0, 0, 0, 0.65);
        img {
          margin-right: 2px;
        }
      }
    }
    li:not(:last-child) {
      margin-bottom: 31px;
    }
  }
}
.ring-model {
  ::v-deep .ant-modal-header {
    background: #f0f2f5;
  }
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 24px;
  }
  .plus {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d9dee5;
    position: absolute;
    top: -7px;
    left: 144px;
  }
  .ring-time {
    padding-left: 116px;
    padding-right: 40px;
    box-sizing: border-box;
    li {
      position: relative;
      width: 68px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9dee5;
      line-height: 32px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 10px;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }
  }
}
</style>
<style>
.custom-cascader-popup {
  background: #222222;
  color: rgba(255, 255, 255, 0.65);
}

.custom-cascader-popup .ant-cascader-menu-item-expand-icon {
  color: rgba(255, 255, 255, 0.65);
}

.custom-cascader-popup
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  color: #009fe8;
  background: transparent;
}

.custom-cascader-popup .ant-cascader-menu-item:hover {
  background: transparent;
}

.custom-cascader-popup .ant-cascader-menu::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 1px;
}

.custom-cascader-popup .ant-cascader-menu::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background: rgba(255, 255, 255, 0.4);
}

.custom-cascader-popup .ant-cascader-menu::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: rgba(255, 255, 255, 0.15);
}
</style>
