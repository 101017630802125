<template>
  <div class="enter-container">
    <nav>
      <img src="@/assets/images/enter_bac.png" class="bac" />
      <div class="top d-f j-c">
        <div class="middle d-f a-c">
          <div class="user-info d-f a-c">
            <p>{{ role }}</p>
            <a-dropdown placement="bottomCenter">
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item key="1">个人中心</a-menu-item>
                <a-menu-item key="2">退出</a-menu-item>
              </a-menu>
              <div class="d-f a-c">
                <img
                  :src="avatarUrl"
                  width="32px"
                  height="32px"
                  style="border-radius: 50%"
                />
                <p class="username">{{ username }}</p>
                <img src="@/assets/images/enter_arrow.png" width="12px" />
              </div>
            </a-dropdown>
          </div>
        </div>
      </div>
    </nav>
    <div class="menu-list d-f j-c">
      <div class="middle d-f f-w">
        <div
          class="item d-f f-d a-c"
          v-for="item in list"
          :key="item.id"
          @click="toMenu(item)"
        >
          <img :src="item.icon" class="box" />
          <p>{{ item.menuName }}</p>
        </div>
      </div>
    </div>

    <a-modal
      v-model="visible"
      :footer="null"
      class="notice"
      :body-style="{ height: '400px', overflowY: 'auto' }"
    >
      <div slot="title">
        <span style="color: rgba(255, 255, 255, 0.85)">新功能介绍</span>
      </div>
      <div class="notice-list">
        <div class="item" v-for="item in noticeShowList" :key="item.id">
          <p>{{ item.createTime }}</p>
          <div class="title">{{ item.name }}</div>
          <div class="ql-snow">
            <div v-html="item.content"></div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { checkToken } from "@/api/login";
import { getCurrentPermissionBox, getLayoutList } from "@/api/acl/menu.js";
import { getNoticeShowList } from "@/api/setting/notice.js";
let avatar = require("@/assets/images/enter_avatar.png");
import { setCookie } from "@/utils/cookie.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      //盒子列表
      list: [],
      //公告弹框的显示隐藏
      visible: false,
      //公告列表
      noticeShowList: [],
    };
  },
  computed: {
    //角色
    role() {
      let roleList = this.$ls.get("userInfo").roles;
      let roles = "";
      roleList.forEach((item) => {
        if (roles != "") {
          roles += "," + item.name;
        } else {
          roles += item.name;
        }
      });
      return roles;
    },
    //用户名
    username() {
      return this.$ls.get("userInfo").username;
    },
    //头像地址
    avatarUrl() {
      if (this.$ls.get("userInfo").headImgUrl) {
        return this.$ls.get("userInfo").headImgUrl;
      } else {
        return avatar;
      }
    },
  },
  methods: {
    //头部下拉菜单选择改变
    handleMenuClick(e) {
      if (e.key == "1") {
        this.$router.push("/user-center");
      } else {
        //退出登录的接口
        this.$confirm({
          title: "您确认退出登录吗？",
          onOk: () => {
            let baseUrl = process.env.VUE_APP_BASEURL;
            let accessToken = this.$ls.get("access_token");
            let refreshToken = this.$ls.get("refresh_token");
            let userId = this.$ls.get("userInfo").id;
            let hostname = location.origin;
            //判断token有没有失效
            checkToken({ token: this.$ls.get("access_token") }).then((response) => {
              if (response.active) {
                window.location = `${baseUrl}/api-uaa/oauth/remove/token?access_token=${accessToken}&refresh_token=${refreshToken}&userId=${userId}&redirect_uri=${hostname}/login`;

                setCookie("access_token", null);
                setCookie("token_type", null);
                setCookie("refresh_token", null);
                setCookie("scope", null);
                this.$ls.set("access_token", null);
                this.$ls.set("token_type", null);
                this.$ls.set("refresh_token", null);
                this.$ls.set("scope", null);
              }
            });
          },
        });
      }
    },
    //获取盒子列表
    getCurrentPermissionBox() {
      getCurrentPermissionBox().then((res) => {
        this.list = res.data;
      });
    },
    //点击盒子跳转
    toMenu(item) {
      //不是外链
      if (item.isFrame === 1) {
        if (item.component != null && item.component != "") {
          this.$router.push({ path: item.path });
        } else {
          getLayoutList({ parentId: item.id }).then((res) => {
            if (res && res.length > 0) {
              //目录
              if (res[0].component == null) {
                this.$router.push({ path: res[0].children[0].path });
                this.$ls.set("parentId", item.id);
              } else {
                this.$router.push({ path: res[0].path });
                this.$ls.set("parentId", item.id);
              }
            }
          });
        }
      }
      //外链
      else {
        window.open(item.path);
      }
    },
    //获取公告展示列表
    getNoticeShowList() {
      getNoticeShowList().then((res) => {
        if (res.data) {
          this.noticeShowList = res.data;
          this.visible = true;
        } else {
          this.visible = false;
        }
      });
    },
    //判断token是否存在以及失效
    checkToken() {
      let accessToken = this.$ls.get("access_token");
      if (accessToken) {
        checkToken({ token: accessToken }).then((res) => {
          if (res.active) {
            this.getCurrentPermissionBox();
            this.getNoticeShowList();
          } else {
            this.$router.push("/login");
          }
        });
      } else {
        this.$router.push("/login");
      }
    },
  },
  created() {
    this.checkToken();
  },
};
</script>

<style scoped lang="less">
.enter-container {
  // min-width: 1440px;
  // overflow-x: auto;
  .middle {
    width: 1200px;
  }
  nav {
    position: relative;
    margin-bottom: 15px;
    .bac {
      width: 100%;
    }
    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      .user-info {
        font-size: 12px;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        color: #ffffff;
        margin-left: auto;
        p {
          margin-right: 8px;
          margin-bottom: 0;
          margin-left: 8px;
        }
        .username {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
  .menu-list {
    margin-bottom: 100px;
    .item {
      margin-top: 33px;
      margin-right: 33px;
      width: 378px;
      height: 302px;
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px 0px 14px rgba(0, 5, 50, 0.07);
      opacity: 1;
      border: 1px solid transparent;
      border-radius: 10px;
      transition: all 1s;
      padding-top: 40px;
      box-sizing: border-box;
      cursor: pointer;
      .box {
        width: 264px;
        height: 188px;
        border-radius: 4px;
      }
      p {
        font-size: 20px;
        font-family: "Microsoft YaHei";
        font-weight: bold;
        line-height: 26px;
        color: #333333;
        margin-top: 16px;
        margin-bottom: 0;
      }
    }
    /* 所有是索引是3（从1开始）的倍数的元素， 去掉margin-right */
    .item:nth-of-type(3n + 0) {
      margin-right: 0px;
    }
    .item:hover {
      border: 1px solid @primary-color;
    }
  }
}

.notice {
  ::v-deep .ant-modal-header {
    background-image: url("~@/assets/images/enter_notice_bac.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  ::v-deep .ant-modal-close {
    color: rgba(255, 255, 255, 0.85);
  }
  .notice-list {
    .item {
      p {
        color: rgba(0, 0, 0, 0.45);
        margin-bottom: 8px;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 12px;
      }
    }
    .item:not(:first-child) {
      margin-top: 24px;
    }
  }
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
}
.ant-dropdown-menu-item-active {
  color: @primary-color;
  background: transparent;
}
</style>
