<template>
  <div class="overview-container">
    <div class="top">
      <div class="top-bac d-f a-c j-c">
        <img src="@/assets/images/data_overview_title.png" />
      </div>
      <div class="back d-f a-c j-b" @click="back">
        <img src="@/assets/images/data_overview_back.png" alt="" />
        <p>返回</p>
      </div>
      <div class="date d-f a-c j-b">
        <div class="date-string">{{ dateString }}</div>
        <div class="week-string">{{ weekString }}</div>
      </div>
      <div class="device d-f j-a a-c">
        <div class="num d-f a-c j-c">
          设备总数
          <div class="box" v-for="(item, index) in totalDeviceCountList" :key="index">
            {{ item }}
          </div>
          <span>台</span>
        </div>
        <div class="num d-f a-c j-c">
          设备在线台数
          <div class="box" v-for="(item, index) in onlineCountList" :key="index">
            {{ item }}
          </div>
          <span>台</span>
        </div>
        <div class="num d-f a-c j-c">
          服务师生总数
          <div class="box" v-for="(item, index) in totalUserList" :key="index">
            {{ item }}
          </div>
          <span>人</span>
        </div>
        <div class="num d-f a-c j-c">
          服务学校总数
          <div class="box" v-for="(item, index) in allSchoolCountList" :key="index">
            {{ item }}
          </div>
          <span>个</span>
        </div>
      </div>
    </div>
    <div ref="map" class="map" id="map"></div>
    <div class="map-left">
      <div class="map-left-title">学校用户数排名(人)</div>
      <div class="map-left-main" ref="mapLeftMain"></div>
    </div>
    <div class="map-right">
      <div class="map-right-title">学校终端数排名(台)</div>
      <div class="map-right-main" ref="mapRightMain"></div>
    </div>
    <div class="map-bottom">
      <div class="map-bottom-title">各时间段终端在线数(台)</div>
      <div ref="mapBottomMain" class="map-bottom-main"></div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import moment from "moment";
require("echarts/extension/bmap/bmap");
import { myStyleJson } from "@/utils/mapStyle.js";
import mapFlag from "@/assets/images/data_overview_map_flag.png"; //引入自定义图标的图片，后面会用到
import { v4 as uuidv4 } from "uuid";
import actionType from "@/utils/action-type";
import { websocketSend } from "@/utils/websocket-send.js";
import { getDeviceOnlineNumList } from "@/api/data/analysis.js";
import { getSchoolDeviceRank, getSchoolUsersRank } from "@/api/data/overview.js";
export default {
  data() {
    return {
      //日期
      dateString: moment().format("YYYY-MM-DD"),
      //星期
      weekString: moment().format("dddd"),
      websocket: null,
      //心跳定时器
      timer: null,
      //查询数量定时器
      timer1: null,
      uuid: null,
      //数据概览数量统计
      dataOverviewNum: {},
      //地图标点数据
      mapFlagList: [],
      //学校用户数排名的学校列表
      schoolList1: [],
      //学校用户数排名的设备数列表
      schoolUserCountList: [],
      //学校终端数排名的学校列表
      schoolList2: [],
      //学校终端数排名的设备数列表
      schoolDeviceCountList: [],
      //各个时间设备在线数
      deviceOnlineNumList: [],
    };
  },
  watch: {
    dataOverviewNum: {
      handler: function (newValue, oldValue) {
        //obj的数据有更新的时侯重绘myCharts1
        if (this.isChange(newValue, oldValue)) {
          this.dataOverviewNum = newValue;
          this.websocketQueryDataOverviewMapFlag();
        }
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    //设备总数
    totalDeviceCountList() {
      if (Object.keys(this.dataOverviewNum).length != 0) {
        let str = this.dataOverviewNum.totalDeviceCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    //设备在线台数
    onlineCountList() {
      if (Object.keys(this.dataOverviewNum).length != 0) {
        let str = this.dataOverviewNum.onlineDeviceCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    //服务师生总数
    totalUserList() {
      if (Object.keys(this.dataOverviewNum).length != 0) {
        let str = this.dataOverviewNum.totalUserCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    //服务学校总数
    allSchoolCountList() {
      if (Object.keys(this.dataOverviewNum).length != 0) {
        let str = this.dataOverviewNum.totalSchoolCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    //返回主页
    back() {
      this.$router.push("/enter");
    },
    //判断数据概览数量统计信息是否变化
    isChange(obj1, obj2) {
      if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
        return false;
      } else if (
        obj1.totalSchoolCount != obj2.totalSchoolCount ||
        obj1.onlineDeviceCount != obj2.onlineDeviceCount ||
        obj1.totalDeviceCount != obj2.totalDeviceCount ||
        obj1.totalUserCount != obj2.totalUserCount
      ) {
        return true;
      } else {
        return false;
      }
    },
    //地图标点
    myCharts1() {
      let myChart = echarts.getInstanceByDom(this.$refs.map);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.map);
      }
      let data = this.mapFlagList;
      let option = {
        bmap: {
          center: [105.114129, 26.550339],
          zoom: 6,
          roam: true,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "#06AFFD",
          padding: [8, 20, 8, 12],
          extraCssText: "border-radius: 11px",
          formatter: function (val) {
            //返回tooltip的内容及布局样式
            return `
              <div style="font-size: 12px;color: #ffffff">学校：${val.data.name}</div>
              <div style="font-size: 12px;color: #ffffff">设备数：${val.data.totalDeviceCount}（${val.data.onlineDeviceCount}台在线）</div>
           `;
          },
        },
        series: [
          {
            name: "设备数量",
            type: "custom", //type的值为'custom'时，表示自定义图标
            coordinateSystem: "bmap",
            data: data,
            renderItem(params, api) {
              let latitude = data[params.dataIndex].latitude; //把坐标数据取出来 纬度
              let longitude = data[params.dataIndex].longitude; //精度
              let arr = [longitude, latitude];
              //具体实现自定义图标的方法
              return {
                type: "image",
                style: {
                  image: mapFlag, // 自定义的图片地址（上面引入，也可直接写图片的网络地址）
                  x: api.coord(arr)[0], // 图标的经度    必须使用 api.coord（）方法对坐标进行转化之后位置才正确
                  y: api.coord(arr)[1], //图标的纬度
                  width: 30, // 图标大小设置
                  height: 30,
                },
              };
            },
          },
        ],
      };
      myChart.setOption(option);
      var bmap = myChart.getModel().getComponent("bmap").getBMap();
      bmap.setMapStyle({ styleJson: myStyleJson });
    },
    //学校用户排名
    myCharts2() {
      let myChart = echarts.getInstanceByDom(this.$refs.mapLeftMain);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.mapLeftMain);
      }
      let option = {
        grid: {
          top: "0%",
          bottom: "-8%",
          left: "5%",
          right: "15%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: this.schoolList1,
        },
        series: [
          {
            type: "bar",
            data: this.schoolUserCountList,
            barWidth: 10,
            z: 10,
            tooltip: { show: false },
            label: {
              show: true, // 显示文本
              position: ["102%", -1], // 数据值位置
              formatter: "{c}人",
              color: "#ffffff",
              fontSize: 12,
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#2D8DEC", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(2, 226, 237, 0.2)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderRadius: [20],
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //学校终端排名
    myCharts3() {
      let myChart = echarts.init(this.$refs.mapRightMain);
      let option = {
        grid: {
          top: "0%",
          bottom: "-8%",
          left: "5%",
          right: "15%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: this.schoolList2,
        },
        series: [
          {
            type: "bar",
            data: this.schoolDeviceCountList,
            barWidth: 10,
            z: 10,
            tooltip: { show: false },
            label: {
              show: true, // 显示文本
              position: ["102%", -1], // 数据值位置
              formatter: "{c}台",
              color: "#ffffff",
              fontSize: 12,
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#2D8DEC", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(2, 226, 237, 0.2)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderRadius: [20],
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //各个时间段在线数
    myCharts4() {
      let myChart = echarts.init(this.$refs.mapBottomMain);
      // 指定图表的配置项和数据
      let option = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "16%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
          data: [
            "0:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          minInterval: 1,
        },
        series: [
          {
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#33CFDD",
              width: 3,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              // color: "rgba(0, 175, 255, 0.3)",
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(5, 243, 234, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: " #0297BB", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.deviceOnlineNumList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#63F2FF",
              type: "dashed",
            },
          },
          backgroundColor: "#33CFDD",
          borderWidth: 0,
          padding: 10,
          textStyle: {
            color: "#ffffff",
          },
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div>${this.dateString} ${obj.time}</div>
             <div style="margin-top:10px">设备在线数：${obj.value}</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = process.env.VUE_APP_WSURL; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      console.log("websocket建立连接");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      this.uuid = uuidv4().replace(/-/g, "");
      let open = websocketSend(this.uuid, actionType.open);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(open));
      }
    },
    //发送心跳
    websocketHeart() {
      console.log("发送心跳");
      let heart = websocketSend(this.uuid, actionType.heart);
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(heart));
        this.timer = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(heart));
          }
        }, 10 * 1000);
      }
    },
    //查询数据概览数量统计信息
    websocketQueryDataOverviewNum() {
      console.log("请求查询数据概览数量统计信息");
      let roles = this.$ls.get("userInfo").roles;
      let roleAliases = roles.map((r) => r.roleAlias).join(",");
      let dataOverviewNum = websocketSend(this.uuid, actionType.dataOverviewNum, {
        roleAliases,
      });
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(dataOverviewNum));
        this.timer1 = setInterval(() => {
          if (this.websocket == null) {
            this.$message.error("连接已断开，请刷新页面");
            this.websocketclose();
          } else {
            this.websocket.send(JSON.stringify(dataOverviewNum));
          }
        }, 5 * 1000);
      }
    },
    //查询数据概览地图标点
    websocketQueryDataOverviewMapFlag() {
      console.log("请求查询数据概览地图标点");
      let roles = this.$ls.get("userInfo").roles;
      let roleAliases = roles.map((r) => r.roleAlias).join(",");
      let dataOverviewMapFlag = websocketSend(this.uuid, actionType.dataOverviewMapFlag, {
        roleAliases,
      });
      if (this.websocket == null) {
        this.$message.error("连接已断开，请刷新页面");
        this.websocketclose();
      } else {
        this.websocket.send(JSON.stringify(dataOverviewMapFlag));
      }
    },
    //数据接收
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log("接收后端返回数据", data);
      //连接成功
      if (data.code === 8000) {
        this.websocketHeart();
        this.websocketQueryDataOverviewNum();
        this.websocketQueryDataOverviewMapFlag();
      } else if (data.code === 8030) {
        this.dataOverviewNum = data.data;
      } else if (data.code === 8031) {
        this.mapFlagList = data.data;
        setTimeout(() => {
          this.myCharts1();
        });
      } else if (data.code < 0) {
        this.$message.error(data.msg);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      this.timer = null;
      this.timer1 = null;
      this.websocket = null;
    },
    //学校用户数排行
    getSchoolUsersRank() {
      getSchoolUsersRank({ limit: 6 }).then((res) => {
        this.schoolList1 = res.data.map((r) => r.schoolName);
        this.schoolUserCountList = res.data.map((r) => r.userCount);
        setTimeout(() => {
          this.myCharts2();
        });
      });
    },
    //学校设备数排行
    getSchoolDeviceRank() {
      getSchoolDeviceRank({ limit: 6 }).then((res) => {
        this.schoolList2 = res.data.map((r) => r.schoolName);
        this.schoolDeviceCountList = res.data.map((r) => r.deviceCount);
        setTimeout(() => {
          this.myCharts3();
        });
      });
    },
    //得到各个时间段和设备在线台数
    getDeviceOnlineNumList() {
      let startTime = this.dateString + " " + "00:00:00";
      let endTime = this.dateString + " " + "23:59:59";
      getDeviceOnlineNumList({ startTime, endTime }).then((res) => {
        let arr = [];
        for (let i = 0; i < 24; i++) {
          arr[i] = 0;
        }
        for (let i = 0; i < 24; i++) {
          res.data.forEach((r) => {
            if (i === Number(r.hourInfo)) {
              arr[i] = r.onlineCount;
            }
          });
        }
        this.deviceOnlineNumList = arr;
        setTimeout(() => {
          this.myCharts4();
        });
      });
    },
  },
  //vue生命周期创建后
  created() {
    this.initWebSocket();
    this.getSchoolUsersRank();
    this.getSchoolDeviceRank();
    this.getDeviceOnlineNumList();
  },
  destroyed() {
    this.websocketclose();
  },
};
</script>

<style lang="less" scoped>
.overview-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  // min-width: 1440px;
  // overflow-x: auto;
  .top {
    width: 100%;
    background: #040e1f;
    height: 160px;
    position: relative;
    .top-bac {
      width: 100%;
      height: 100px;
      background-image: url("~@/assets/images/data_overview_bac.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 101% 106%;
      color: #bae0ff;
      font-size: 38px;
      img {
        height: 80%;
      }
    }
    .back {
      position: absolute;
      width: 65px;
      height: 20px;
      top: 25px;
      right: 20px;
      cursor: pointer;
      img {
        height: 80%;
        width: 25px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #4f95cc;
        margin: 0;
      }
    }
    .date {
      position: absolute;
      width: 150px;
      height: 20px;
      top: 25px;
      left: 20px;
      .date-string {
        font-size: 14px;
        font-weight: 400;
        color: #4f95cc;
      }
      .week-string {
        font-size: 14px;
        font-weight: 400;
        color: #4f95cc;
      }
    }
    .device {
      width: 100%;
      height: 60px;
      .num {
        height: 34px;
        font-size: 20px;
        font-weight: 500;
        color: #038ffb;
        .box {
          margin-left: 8px;
          width: 22px;
          height: 34px;
          background: linear-gradient(0deg, #0368da, rgba(3, 104, 218, 0));
          border-image: linear-gradient(
              0deg,
              rgba(68, 200, 255, 0.8),
              rgba(75, 202, 255, 0.8)
            )
            1 1;
          border-radius: 3px;
          color: #01fff6;
          font-family: "Segment7";
          text-align: center;
          line-height: 34px;
        }
        span {
          margin-left: 8px;
        }
      }
    }
  }
  .map {
    height: calc(~"100vh - 160px");
    width: 100%;
    background: #040e1f;
  }
  .map-left {
    position: absolute;
    bottom: calc(~"30% + 30px");
    left: 20px;
    width: 20%;
    height: 45%;
    background: url("~@/assets/images/data_overview_map_left.png") no-repeat center
      center/100% 100%;
    .map-left-title {
      font-weight: bold;
      font-size: 16px;
      color: #17caf0;
      margin-top: 20px;
      margin-left: 20px;
    }
    .map-left-main {
      width: 100%;
      height: 80%;
    }
  }
  .map-right {
    position: absolute;
    bottom: calc(~"30% + 30px");
    right: 20px;
    width: 20%;
    height: 45%;
    background: url("~@/assets/images/data_overview_map_right.png") no-repeat center
      center/100% 100%;
    .map-right-title {
      font-weight: bold;
      font-size: 16px;
      color: #17caf0;
      margin-top: 20px;
      margin-left: 20px;
    }
    .map-right-main {
      width: 100%;
      height: 80%;
    }
  }
  .map-bottom {
    position: absolute;
    bottom: 15px;
    width: 97%;
    height: 30%;
    left: 20px;
    right: 20px;
    background: url("~@/assets/images/data_overview_map_bottom.png") no-repeat center
      center/100% 100%;
    .map-bottom-title {
      font-weight: bold;
      font-size: 16px;
      color: #17caf0;
      margin-top: 15px;
      margin-left: 20px;
    }
    .map-bottom-main {
      width: 100%;
      height: 80%;
    }
  }
}
::v-deep .anchorBL {
  display: none;
}
</style>
